import React from 'react'

import { Image } from 'react-bootstrap';
import { Link, useLocation,useParams } from 'react-router-dom'
export default function HotelBookingFailure() {
     let { id } = useParams();
    return (
        <section className="bg-inner section-b-space success-section">
            <div className="container">
                <div className="row success-detail mt-0">
                    <div className="col">
                        <Image src={ require("../assets/images/hotel/booking-failed.png")} className="img-fluid blur-up lazyload" alt="" />
                        <h2>oops ! we are unable to process your payment</h2>
                        <p>looks like we encountered an error. please try again. if you continue to have issue, try another
                            payment method.</p>
                        <Link to="/hotel-booking" className="btn btn-solid color1">try again</Link>
                    </div>
                </div>
            </div>
        </section>

    )
}
