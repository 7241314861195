import React,{useState} from 'react'
import {Modal} from 'react-bootstrap';

import {  useSelector  } from "react-redux";
//import { useNavigate } from "react-router-dom";
import {useActions} from "../../redux/actions";
import * as authActions from "../../redux/actions/auth";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default function LoginDetails() {

    const [emailshow, setEmailShow] = useState(false);
    const [phoneshow, setPhoneShow] = useState(false);
    const [passwordshow, setPasswordShow] = useState(false);
    const user = useSelector((state)=> state.user)
    const {updateEmail,updatePhone , updatePassword} = useActions(authActions)
    const userDetail = user&&user.data?user.data.data:{};

    //email form
    const [old_email,setOldEmail] = useState('')
    const [new_email,setNewEmail] = useState('')
    const [confirm_email,setConfirmEmail] = useState('')
    const [old_emailErr,setOldEmailErr] = useState(false)
    const [new_emailErr,setNewEmailErr] = useState(false)
    const [confirm_emailErr,setConfirmEmailErr] = useState(false)


    //phone form
    const [old_phone,setOldPhone] = useState('')
    const [new_phone,setNewPhone] = useState('')
    const [confirm_phone,setConfirmPhone] = useState('')
    const [old_phoneErr,setOldPhoneErr] = useState(false)
    const [new_phoneErr,setNewPhoneErr] = useState(false)
    const [confirm_phoneErr,setConfirmPhoneErr] = useState(false)

    //phone form
    const [old_pwd,setOldPwd] = useState()
    const [new_pwd,setNewPwd] = useState()
    const [confirm_pwd,setConfirmPwd] = useState()
    const [old_pwdErr,setOldPwdErr] = useState(false)
    const [new_pwdErr,setNewPwdErr] = useState(false)
    const [confirm_pwdErr,setConfirmPwdErr] = useState(false)



    const changeEmailForm =(e) => {
        e.preventDefault();
        var err = false
        if(!old_email){
            err= true
            setOldEmailErr(true)
        }
        if(!new_email){
            err= true
            setNewEmailErr(true)
        }
        if(!confirm_email){
            err= true
            setConfirmEmailErr(true)
        }
        if(confirm_email!=new_email){
            err= true
            setConfirmEmailErr(true)
        }

        if(err){
            return false;
        }

         let payload = {
            old_email:old_email,
            new_email:new_email,
            confirm_email:confirm_email,
            token:user.data.token          
        }
        updateEmail(payload).then(async (res)=>{       
            if(res ===true){     
                setEmailShow(false)
            }      
        });

    }

    const changePhoneForm =(e) => {
         e.preventDefault();
         var err = false;
         if(!old_phone){
            err = true
            setOldPhoneErr(true)
         }
         if(!new_phone){
            err = true
            setNewPhoneErr(true)
         }
         if(!confirm_phone){
            err = true
            setConfirmPhoneErr(true)
         }
         if(new_phone!=confirm_phone){
            err = true
            setConfirmPhoneErr(true)
         }

         if(err){
            return false;
         }
         let payload = {
            old_phone:old_phone,
            new_phone:new_phone,
            confirm_phone:confirm_phone,
            token:user.data.token          
        }
        updatePhone(payload).then(async (res)=>{       
            if(res ===true){     
                setPhoneShow(false)
            }       
        });
    }

    const changePasswordForm =(e) => {
        
        e.preventDefault();
        var err = false
       
        if(!old_pwd){
            err = true
            setOldPwdErr(true)
        }
        if(!new_pwd){
            err = true
            setNewPwdErr(true)
        }
        if(!confirm_pwd){
            err = true
            setConfirmPwdErr(true)
        }
        if(new_pwd != confirm_pwd){
            err = true
            setConfirmPwdErr(true)
        }
        if(err){
            return false;
        }
        let payload = {
            old_password:old_pwd,
            new_password:new_pwd,
            confirm_password:confirm_pwd,
            token:user.data.token          
        }
        updatePassword(payload).then(async (res)=>{       
            if(res ===true){     
                setPasswordShow(false)
            }       
        });
    }

    return (
        <>
             <div class="dashboard-title">
                <h4>login details</h4>
            </div>
            <div class="dashboard-detail">
                <ul>
                    <li>
                        <div class="details">
                            <div class="left">
                                <h6>email address</h6>
                            </div>
                            <div class="right">
                                <h5 style={{textTranform:"lowercase !important"}} className="lower">{userDetail.email}</h5>
                                <span data-bs-toggle="modal"
                                    data-bs-target="#edit-address" onClick={()=>setEmailShow(true)}>edit</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="details">
                            <div class="left">
                                <h6>phone no:</h6>
                            </div>
                            <div class="right">
                                <h6>{userDetail.phone_number}</h6>
                                <span data-bs-toggle="modal" data-bs-target="#edit-phone" onClick={()=>setPhoneShow(true)}>edit</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="details">
                            <div class="left">
                                <h6>password</h6>
                            </div>
                            <div class="right">
                                <h6>&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;</h6>
                                <span data-bs-toggle="modal"
                                    data-bs-target="#edit-password" onClick={()=>setPasswordShow(true)}>edit</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>


            <Modal show={emailshow} onHide={()=>setEmailShow(false)} className=" edit-profile-modal">
                            <div class="modal-header">
                                <h5 class="modal-title">change email address</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setEmailShow(false)}> </button>
                            </div>

                            <form onSubmit={changeEmailForm}>

                            <div class="modal-body">
                                
                                    <div class="row">
                                        <div class="form-group col-12">
                                            <label for="old">old email</label>
                                            <input type="email" class="form-control" id="old" value={old_email} onChange={(e)=>{setOldEmail(e.target.value)
                                            setOldEmailErr(false)
                                            }} />
                                            {old_emailErr && <p className="field-err">Please enter old email </p>}
                                        </div>
                                        <div class="form-group col-12">
                                            <label for="new">enter new email</label>
                                            <input type="email" class="form-control" id="new" value={new_email} onChange={(e)=>{setNewEmail(e.target.value)
                                             setNewEmailErr(false)
                                            } } />
                                            {new_emailErr && <p className="field-err">Please enter new email </p>}
                                        </div>
                                        <div class="form-group col-12">
                                            <label for="comfirm">confirm your email</label>
                                            <input type="email" class="form-control" id="comfirm" value={confirm_email} onChange={(e)=>{setConfirmEmail(e.target.value)
                                             setConfirmEmailErr(false)
                                            } } />
                                            {confirm_emailErr && <p className="field-err">Please enter confirm email </p>}
                                        </div>
                                    </div>
                                
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>{setEmailShow(false)}} >Close</button>
                                <button type="submit" class="btn btn-solid">Save changes</button>
                            </div>
                            </form>
                </Modal>

                <Modal show={phoneshow} onHide={()=>setPhoneShow(false)} className=" edit-profile-modal">
                            <div class="modal-header">
                                <h5 class="modal-title">Change phone no</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setPhoneShow(false)}> </button>
                            </div>
                            <form onSubmit={changePhoneForm}>
                            <div class="modal-body">
                               
                                    <div class="row">

                                    <div class="form-group col-12">
                                       <label for="ph-o">old phone no</label>
                                        <PhoneInput
                                        country={'in'}
                                  placeholder="Enter phone number"
                                  value={old_phone}
                                  onChange={(value) => {                                 
                                    setOldPhone(value)
                                    setOldPhoneErr(false)
                                  }}  
                                  />
                                    {old_phoneErr && <p className="field-err">Please enter old phone </p>}
                                    </div>

                                       
                                        <div class="form-group col-12">
                                            <label for="ph-n">enter new phone no</label>
                                            <PhoneInput
                                             country={'in'}
                                          
                                              placeholder="Enter phone number"
                                              value={new_phone}
                                              onChange={(value) => {                                 
                                                setNewPhone(value)
                                                setNewPhoneErr(false)
                                              }}  
                                              
                                              />
 {new_phoneErr && <p className="field-err">Please enter new phone </p>}
                                        </div>
                                        <div class="form-group col-12">
                                            <label for="ph-c">confirm your phone no</label>
                                            <PhoneInput
                                             country={'in'}
                                            
                                              placeholder="Enter phone number"
                                              value={confirm_phone}
                                              onChange={(value) => {                                 
                                                setConfirmPhone(value)
                                                setConfirmPhoneErr(false)
                                              }}  
                                             />
 {confirm_phoneErr && <p className="field-err">Please enter  correct confirm phone </p>}
                                        </div>
                                    </div>
                                
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>setPhoneShow(false)}>Close</button>
                                <button type="submit" class="btn btn-solid">Save changes</button>
                            </div>
                            </form>
                    </Modal>

                    <Modal show={passwordshow} onHide={()=>setPasswordShow(false)} className=" edit-profile-modal">
                            <div class="modal-header">
                                <h5 class="modal-title">change password</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setPasswordShow(false)}></button>
                            </div>
                            <form onSubmit={changePasswordForm}>
                            <div class="modal-body">
                                
                                    <div class="row">
                                        <div class="form-group col-12">
                                            <label for="p-o">old password</label>
                                            <input type="password" class="form-control" id="p-o" value={old_pwd} onChange={(e)=>{setOldPwd(e.target.value)
                                            setOldPwdErr(false)
                                            } }/>
                                            {old_pwdErr  && <p className="field-err">Please enter old password </p>}
                                        </div>
                                        <div class="form-group col-12">
                                            <label for="p-n">enter new password</label>
                                            <input type="password" class="form-control" id="p-n" value={new_pwd} onChange={(e)=>{setNewPwd(e.target.value)
                                             setNewPwdErr(false)
                                            } }/>
                                            {new_pwdErr && <p className="field-err">Please enter  new  password </p>}
                                        </div>
                                        <div class="form-group col-12">
                                            <label for="p-c">confirm your password</label>
                                            <input type="password" class="form-control" id="p-c" value={confirm_pwd} onChange={(e)=>{setConfirmPwd(e.target.value)
                                            setConfirmPwdErr(false)
                                            }} />
                                            {confirm_pwdErr && <p className="field-err">Please enter  correct confirm password </p>}
                                        </div>
                                    </div>                                
                            </div>
                           
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>setPasswordShow(false)}>Close</button>
                                <button type="submit" class="btn btn-solid">Save changes</button>
                            </div>
                            </form>
                    </Modal>
        </>
    )
}
