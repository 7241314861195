import React,{useState,useEffect} from 'react'
import { Modal} from 'react-bootstrap';
//import { Link, useLocation } from 'react-router-dom'
import {  useSelector  } from "react-redux";
import { useNavigate } from "react-router-dom";
import {useActions} from "../../redux/actions";
import * as authActions from "../../redux/actions/auth";
import moment from 'moment';

import 'react-datetime/css/react-datetime.css';
import DatePicker from 'react-datetime';
import {service} from '../../services.js'
import ImageUploading from 'react-images-uploading';

export default function Profile() {

    const [show, setShow] = useState(false);
    const user = useSelector((state)=> state.user)
    const {profileAction} = useActions(authActions)


    const userDetail = user&&user.data?user.data.data:{};
    console.log(userDetail,'userDetailuserDetail')
    const navigate = useNavigate(); 
    const [fname,setFname] = useState(userDetail?userDetail.first_name:'')
    const [lname,setLname] = useState(userDetail.last_name)
    const [dob,setDob] = useState(userDetail.dob?moment(userDetail.dob).format("MM/DD/YYYY"):'')
    const [gender,setGender] = useState(userDetail.gender)
    const [city,setCity] = useState(userDetail.city)
    const [country,setCountry] = useState(userDetail.country_id)
    const [state,setState] = useState(userDetail.state)
    const [zip,setZip] = useState(userDetail.zip)
    const [address,setAddress] = useState(userDetail.address)

    const [fnameErr,setFnameErr] = useState(false)
    const [lnameErr,setLnameErr] = useState(false)
    const [dobErr,setDobErr] = useState(false)
    const [genderErr,setGenderErr] = useState(false)
    const [cityErr,setCityErr] = useState(false)
    const [countryErr,setCountryErr] = useState(false)
    const [stateErr,setStateErr] = useState(false)
    const [zipErr,setZipErr] = useState(false)
    const [addressErr,setAddressErr] = useState(false)

    const [profileImg,setProfileImg] = useState([])
   

    const [countries,setCountries] = useState([])
    const [states,setStates] = useState([])
    const [cities,setCities] = useState([])

    const today = moment();
    const disableFutureDt = current => {
      return current.isBefore(today)
    }

    const onChange = (imageList, addUpdateIndex,errors) => {
        setProfileImg(imageList);
    };


    const handleSubmit = (e)=> {

        e.preventDefault();
        setFnameErr(false)
        setLnameErr(false)
        setDobErr(false)
        setGenderErr(false)
        setCityErr(false)
        setStateErr(false)
        setStateErr(false)
        setCountryErr(false)
        setZipErr(false)
        setAddressErr(false)

        var err = false;
        if(!fname){
            setFnameErr(true)
            err = true
        }
        if(!lname){
            setLnameErr(true)
            err = true
        }
        if(!dob){
            setDobErr(true)
            err = true
        }
        if(!gender){
            setGenderErr(true)
            err = true
        }
        if(!city){
            setCityErr(true)
            err = true
        }
        if(!state){
            setStateErr(true)
            err = true
        }
        if(!country){
            setCountryErr(true)
            err = true
        }
        console.log(zip,'zip')
        if(!zip){
            setZipErr(true)
            err = true
        }
        if(zip.length>7 || zip.length<6){
            setZipErr(true)
            err = true
        }
        if(!address){
            setAddressErr(true)
            err = true
        }
        if(err){
            return false;
        }
        let payload = {
            first_name:fname,
            last_name:lname,
            dob:moment(dob).format("YYYY-MM-DD"),
            gender:gender,
            city:city,
            state:state,
            country:country,
            zip:zip,
            address:address,
            token:user.data.token,
            profile_img:profileImg
        }
        profileAction(payload).then(async (res)=>{       
            if(res ===true){     
                setShow(false)         
            }       
        });
    }
    useEffect(async () => {  
        if(!user || !user.data){           
            navigate("/");   
        }

        var d = await service.getRequest('getCountries')       
        if(d.status===200){           
           setCountries(d.data.data)
        }


    },[]);


    useEffect(async () => {  
        var d = await service.getRequest('getCities/'+state)       
        if(d.status===200){           
           setCities(d.data.data)
        }

    },[state]);


    useEffect(async () => {  
        var d = await service.getRequest('getStates/'+country)       
        if(d.status===200){           
           setStates(d.data.data)
        }

    },[country]);




    return (
        <>
            <div class="dashboard-title">
                <h4>profile</h4>
                <span data-bs-toggle="modal" data-bs-target="#edit-profile" onClick={()=>setShow(true)}>edit</span>
            </div>
            <div class="dashboard-detail">
                <ul>
                    <li>
                        <div class="details">
                            <div class="left">
                                <h6>name</h6>
                            </div>
                            <div class="right">
                                <h6>{userDetail.first_name} {userDetail.last_name}</h6>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="details">
                            <div class="left">
                                <h6>birthday</h6>
                            </div>
                            <div class="right">
                                <h6> 
                                    {userDetail.dob && 
                                moment(userDetail.dob).format("MM/DD/YYYY")}
                                </h6>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="details">
                            <div class="left">
                                <h6>gender</h6>
                            </div>
                            <div class="right">
                                <h6>{userDetail.gender}</h6>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="details">
                            <div class="left">
                                <h6>street address</h6>
                            </div>
                            <div class="right">
                                <h6>{userDetail.address}</h6>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="details">
                            <div class="left">
                                <h6>city/state</h6>
                            </div>
                            <div class="right">
                                <h6>{userDetail.city_detail?.name}</h6>
                            </div>
                        </div>  
                    </li>
                    <li>
                        <div class="details">
                            <div class="left">
                                <h6>zip</h6>
                            </div>
                            <div class="right">
                                <h6>{userDetail.zip}</h6>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>


                <Modal show={show} onHide={()=>setShow(false)} className=" edit-profile-modal">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Edit Profile</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setShow(false)}></button>
                            </div>
                            <form onSubmit={handleSubmit}>
                            <div class="modal-body">
                                
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <label for="first">first name</label>
                                            <input type="text" class="form-control" id="first" placeholder="first name" value={fname} onChange={(e)=>{setFname(e.target.value)
                                            setFnameErr(false)
                                            }} />
                                            {fnameErr && <p className="field-err">Please enter First Name </p>}

                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="last">last name</label>
                                            <input type="text" class="form-control" id="last" placeholder="last name" value={lname} onChange={(e)=>{setLname(e.target.value)
                                             setLnameErr(false)
                                            } } />
                                            {lnameErr && <p className="field-err">Please enter Last Name </p>}
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="gender">gender</label>
                                            <select id="gender" class="form-control" value={gender} onChange={(e)=>{setGender(e.target.value)
                                             setGenderErr(false)
                                            }} >
                                                <option value="">Choose...</option>
                                                <option value="female">Female</option>
                                                <option value="male">Male</option>
                                            </select>
                                            {genderErr && <p className="field-err">Please select gender </p>}
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label>birthday</label>
                                             <DatePicker
                                        value={dob}
                                        onChange={val => {setDob(val)
                                        setDobErr(false)
                                        }}
                                        dateFormat="MM/DD/YYYY" timeFormat={false}
                                        isValidDate={disableFutureDt}
                                      />
                                        {dobErr && <p className="field-err">Please Select DOB </p>}
                                        </div>
                                        <div class="form-group col-12">
                                            <label for="inputAddress">Profile Image</label>
                                            <ImageUploading
                              
                              value={profileImg}
                              onChange={onChange}
                              maxNumber={1}
                              dataURLKey="data_url"
                         
                            >
                              {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps
                                
                              }) => (
                                // write your building UI
                                <div className="upload__image-wrapper"  onClick={onImageUpload}
                                    {...dragProps}>
                                  
                                  &nbsp;
                             
                                  <div className="photo-container" style={{marginTop:"15px"}}>
                                  {imageList.length===0 && (
                                      <div  className="image-item" onClick={onImageUpload}>
                                      <div className='add-phots text-center'>
                                           <img alt="img" src='https://idowaz.com/public/image/system/upload-cloud-2.png' className='img-fluid complete-profile-img' />
                                        </div>
                                      </div>
                                  )}
                                  {imageList.map((image, index) => (
                                    <div key={index} className="image-item" >
                                      <img src={image['data_url']} alt="" width="250" />
                                      <div className="image-item__btn-wrapper">
                                        <button onClick={() => onImageUpdate(index)}>Update</button>
                                        <button onClick={() => onImageRemove(index)}>Remove</button>
                                      </div>
                                    </div>
                                  ))}
                                  </div>
                                </div>
                              )}
                            </ImageUploading>
                            </div>
                                <div class="form-group col-12">
                                    <label for="inputAddress">Address</label>
                                    <input type="text" class="form-control" id="inputAddress" placeholder="1234 Main St" value={address} onChange={(e)=>{setAddress(e.target.value)
                                    setAddressErr(false)
                                    }}/>
                                    {addressErr && <p className="field-err">Please enter address </p>}
                                </div>
                                <div class="form-group col-12">
                                    <label for="inputAddress">Country</label>
                                    <select class="form-control" name="country" onChange={(e)=>{setCountry(e.target.value)
                                    setCountryErr(false)
                                    }} value={country}>
                                    <option value="">Select Country</option>
                                        {countries.map(function(object, i){
                                        return (<option value={object.id} key={i}>{object.name}</option>)
                                        })}
                                    </select>
                                    {countryErr && <p className="field-err">Please select country </p>}
                                </div>

                                
                                <div class="form-group col-md-4">
                                    <label for="inputState">State</label>
                                        <select class="form-control" name="state" onChange={(e)=>{setState(e.target.value)
                                         setStateErr(false)
                                        }} value={state} >
                                        <option value="">Select State</option>
                                        {states.map(function(object, i){
                                        return (<option value={object.id} key={i}>{object.name}</option>)
                                        })}
                                    </select>
                                    {stateErr && <p className="field-err">Please select state </p>}

                                </div>

                                <div class="form-group col-md-5">
                                    <label for="inputCity">City</label>
                                        <select class="form-control" name="city" onChange={(e)=>{setCity(e.target.value)
                                         setCityErr(false)
                                        } } value={city}>
                                        <option value="">Select City</option>
                                        {cities.map(function(object, i){
                                        return (<option value={object.id} key={i}>{object.name}</option>)
                                        })}
                                    </select>
                                    {cityErr && <p className="field-err">Please select city </p>}
                                </div>

                                <div class="form-group col-md-3">
                                    <label for="inputZip">Zip</label>
                                    <input type="text" class="form-control" id="inputZip" value={zip} onChange={(e)=>{setZip(e.target.value)
                                     setZipErr(false)
                                    }} />
                                    {zipErr && <p className="field-err">Please enter correct zipcode </p>}
                                </div>
                            </div>
                               
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>{setShow(false)}}>Close</button>
                                <button type="submit" class="btn btn-solid">Save changes</button>
                            </div>
                        </form>
                </Modal>

              

                <div class="modal fade edit-profile-modal" id="delete-account" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Account deletion request</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                            </div>
                            <div class="modal-body">
                                <p class="text-dark">Before you leave, please tell us why you'd like to delete your Idowaz account.
                                    This information will help us improve. (optional)</p>
                                <form>
                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-solid">Delete my account</button>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}
