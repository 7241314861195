import {COUPON_ADD} from "../actionTypes";

const initialState = localStorage.getItem('coupon')?JSON.parse(localStorage.getItem('coupon')):{};
console.log('initialState',initialState)

const coupon = (state = initialState, action) => {

  console.log(action.type,'action.type')
  switch (action.type) {
    
    case COUPON_ADD: {
     

      	const coupon = JSON.stringify(action.payload);

	    localStorage.setItem('coupon', coupon);
       window.dispatchEvent(new Event("coupon"));
	    return state;
      
     }


    default:
      return state;
  }
}

export default coupon;