import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg';
import logoname from '../assets/images/idowaz.svg'; 
import footerLogo from '../assets/images/footer-logo.svg'; 

import USA from '../assets/images/USA.svg'; 
import UK from '../assets/images/UK.svg'; 
import Europe from '../assets/images/Europe.svg'; 
import france from '../assets/images/france-flag.svg'; 
import india from '../assets/images/india.png'; 
import romania from '../assets/images/romania-flag.svg'; 
import Affrica from '../assets/images/Affrica.svg'; 
import southAfrica from '../assets/images/south-africa-flag.svg'; 
import footermap from '../assets/images/footer-maps.png'; 

const Footer = (props) => {


    return (
        <footer>
            <>
            <div class="bd-footer py-5 footer-map">
    <div class="container py-5">
        <h1>Our Offices</h1>
        <div class="row">
            <div class="col-lg-2 country-card">
                <div class="card my-4 p-3">
                    <div class="flag">
                        <img src={USA} />
                    </div>
                    <h5>USA</h5>
                </div>
                <div class="card my-4 p-3">
                    <div class="flag">
                        <img src={UK} />
                    </div>
                    <h5>UK</h5>
                </div>
                <div class="card my-4 p-3">
                    <div class="flag">
                        <img src={Europe} />
                    </div>
                    <h5>Germany</h5>
                </div>
                <div class="card my-4 p-3">
                    <div class="flag">
                        <img src={france} />
                    </div>
                    <h5>France</h5>
                </div>
            </div>
            <div class="col-lg-8 map-image">
                <img src={footermap} width="100%" />
            </div>
            <div class="col-lg-2 country-card">
				<div class="card my-4 p-3">
					<a target="_blank" href="https://mittaltechnologies.com">
                    <div class="flag">
                        <img style={{maxHeight:"30px"}} src={india} />
                    </div>
                    <h5>Bharat (Mittal Technologies)</h5>
					</a>
                </div>
                <div class="card my-4 p-3">
                    <div class="flag">
                        <img src={romania} />
                    </div>
                    <h5>Romania</h5>
                </div>
                <div class="card my-4 p-3">
                    <div class="flag">
                        <img src={Affrica}/>
                    </div>
                    <h5>Nigeria</h5>
                </div>
                <div class="card my-4 p-3">
                    <div class="flag">
                        <img src={southAfrica} />
                    </div>
                    <h5>South Africa</h5>
                </div>
            </div>
        </div>
    </div>
</div>
            <div className="container">
                <div className="footer">
                    <div className="row">

                        <div className="col-lg-3 col-md-6">
                            <div className="footer_logo">
                                <h5>
                                    <Link to="#"><Image width="150" className="img-responsive" src={footerLogo} /></Link>
                                </h5>
                                <ReactSVG  width="150" className="img-responsive" src={logoname} />
                              
                                <p>
                                    Customer service enquiries. <br /> 
                                        Questions about your order?<br />
                                            We are here to help!<br />
                                                Email us at support@idowaz.com <br />
                                </p>
                                <div className="footer_app">
                                  
                                    <ul class="list-unstyled d-flex">
                                        <li class="me-3"><a class="text-muted" target="_blank" href="https://www.facebook.com/idowaztech"><img src={require("../assets/images/facebook.png")} alt="" /></a>
                                        </li>
                                        <li class="me-3"><a class="text-muted" target="_blank" href="https://www.instagram.com/idowaztechnology/"><img src={require("../assets/images/instagram.png")} alt="" /></a>
                                        </li>
                                        <li class="me-3"><a class="text-muted" target="_blank" href="https://www.linkedin.com/company/idowaz-technologie-limited/"><img src={require("../assets/images/linkedin.png")} alt="" /></a>
                                        </li>
                                        <li class="me-3"><a class="text-muted" target="_blank" href="https://twitter.com/Idowaztech" alt=""><img src={require("../assets/images/twitter.png")} alt="" /></a>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6">
                            <div className="footer_box">
                                <h5>Our Services</h5>
                                <ul className="list-unstyled">
                                    <li> <a href="https://idowazhomeservices.com" target="_blank"><span> Homecare </span> </a> </li>
                                    <li> <a href="https://idowaz.com/transportation" target="_blank"><span> Transportation </span> </a> </li>
                                    <li> <a href="https://idowazmenus.com" target="_blank"><span> Eat </span> </a> </li>
                                    <li> <a href="https://idowazcourier.com/" target="_blank"><span> Courier </span> </a> </li>
                                    <li> <a href="https://idowaz.com/ride-sharing" target="_blank"><span> Ride Sharing </span> </a> </li>
                                    <li> <a href="/" target="_blank"><span> Hotels &amp; Travel </span> </a> </li>

                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div className="footer_box">
                                <h5>Quick Links</h5>
                                <ul className="list-unstyled">
                                    <li> <a href="https://idowaz.com/contact-us" target="_blank"><span> Contact Us </span> </a> </li>
                                    <li> <a href="https://idowaz.com/about-us" target="_blank"><span> About Us </span> </a> </li>
                                    <li> <a href="https://idowaz.com/job" target="_blank"><span> Careers </span> </a> </li>
                                    <li> <a href="https://blog.idowaz.com" target="_blank"><span> Blog </span> </a> </li>
                                  
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6">
                            <div className="footer_box">
                                <h5>Company</h5>
                                <ul className="list-unstyled">
                                    <li> <a href="https://idowaz.com/faqs" target="_blank"><span> FAQs </span> </a> </li>
                                    <li> <a href="https://idowaz.com/privacy-policy" target="_blank"><span> Privacy Policy </span> </a> </li>
                                    <li> <Link to="terms-conditions" ><span> Terms &amp; Conditions </span> </Link> </li>

                                  
                                </ul>
                            </div>
                        </div>

                     
                        <div className="col-lg-2 col-md-6">
                            <div className="get_in_touch">
                                <a className="" target="_blank" href="https://apps.apple.com/us/app/idowaz/id1493955544">
                                        <Image width="100px" className="img-fluid" src={require("../assets/images/new/app-store.png")} />
                                     </a>
                                    <a className="" style={{marginTop:"5px"}} target="_blank" href="https://play.google.com/store/apps/details?id=idowaz.com.idowaz&amp;hl=en_IN&amp;gl=US">
                                        <Image width="100px" className="img-fluid" src={require("../assets/images/new/google-play.png")} />
                                    </a> 
                            </div>

                        

                        </div>
                        
                    </div>

                </div>

                <div className="copyright">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 copyright-box">
                            <p className="copy-text">© Copyright 2023 Idowaz Inc. All Rights Reserved. Idowaz</p>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 copyright-box">
                            <p style={{float: "right",marginRight: "11%",color:"white"}}>Developed By <a href="http://mittaltechnologies.com" target="_blank" class="text-primary">Mittal Technologies</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
        </footer>



    )

}

export default Footer;