import React , {useState} from 'react'
import { Link } from 'react-router-dom'
//import { Image ,Tab} from 'react-bootstrap'; 
import DatePicker from 'react-datetime';
import moment from 'moment';
import Autocomplete from "react-google-autocomplete";
import { useNavigate } from "react-router-dom";

export default function HotelSearch() {

    const [checkIn,setCheckIn] = useState();
    const [address,setAddress] = useState('');
    const [latitude,setLatitude] = useState('');
    const [longitude,setLongitude] = useState('');
    const [checkOut,setCheckOut] = useState();
    const [rooms, setRooms] = useState(1)
    const today = moment().subtract(1, 'days');
     const [adults,setAdults] = useState(2);
    const [childrens,setChildrens] = useState(0);
    const disableFutureDt = current => {
      return current.isAfter(today)
    }
    const disableFutureDtCheckout = current => {
      return current.isAfter(checkIn)
    }

    const navigate = useNavigate();

    const  submit = () => {
         navigate("/hotels?rooms="+rooms+"&lat="+latitude+"&lng="+longitude+"&adults="+adults+"&childrens="+childrens+"&address="+address);   
    }
    return (
       <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 mb-4">
                <div className="hotel_chk_img">
                    <img src={require("../../assets/images/new/hotel-2.png")} className="img-fluid" alt="idowaz" />
                </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-12 hotel_detail">
                <h2 className="new-title text-center"><span>Find Hotel Deals</span></h2>
                <div className="row">
                    <div className="col-md-12 col-sm-12 form-group">
                        <label className="form-label">Country:</label>
                        <div className="input-group">
                        <Autocomplete
                        className="form-control" 
                              apiKey={'AIzaSyDc-cNs0EnMKNz7wlsQciciZO5PDgDqPcU'}
                              onPlaceSelected={(place) => {
                              console.log(place.formatted_address)
                              setAddress(place.formatted_address);
                                setLatitude(place.geometry.location.lat())
                                setLongitude(place.geometry.location.lng())
                              }}
                            />

                          
                            <span className="input-group-text"><i className="fas fa-map-marker-alt"></i></span>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4 form-group">
                        <label className="form-label">Rooms:</label>
                        <select className="form-control" value={rooms} onChange={(e)=>setRooms(e.target.value)} >
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                        </select>
                    </div>
                    <div className="col-md-4 col-sm-4 form-group btn-div">
                        <label className="form-label">Adults:</label>
                        <input type="number"  className="form-control" value={adults} onChange={(e)=>setAdults(e.target.value)}/>
                    </div>
                    <div className="col-md-4 col-sm-4 form-group btn-div">
                        <label className="form-label">Children:</label>
                        <input type="number" className="form-control" value={childrens}  onChange={(e)=>setChildrens(e.target.value)} />
                    </div>

                    <div className="col-sm-6">
                        <label className="form-label">Check In</label>
                        <div className="input-group">
                            <DatePicker
                            inputProps={{"placeholder":"Check In","class":"form-control"}}
                            value={checkIn}
                            onChange={val => setCheckIn(val)}
                            dateFormat="YYYY-MM-DD" timeFormat={false}
                            isValidDate={disableFutureDt}
                          />
                            <div className="input-group-text"><i className="fas fa-calendar-alt"></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <label className="form-label">Check Out</label>
                        <div className="input-group">
                        <DatePicker
                              inputProps={{"placeholder":"Check Out","class":"form-control"}}
                              value={checkOut}
                              onChange={val => setCheckOut(val)}
                              dateFormat="YYYY-MM-DD" timeFormat={false}
                              isValidDate={disableFutureDtCheckout}
                            />
                            <div className="input-group-text"><i className="fas fa-calendar-alt"></i>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 mt-4">
                        <button className="btn btn-solid color1" onClick={submit}>Check Availability</button>
                    </div>
                </div>
            </div>

        </div>
    )
}
