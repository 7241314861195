import {LOGIN, ALERT , ADD_CART , REMOVE_CART,EMPTY_CART,COUPON_ADD} from '../actionTypes';
import axios from 'axios';

import config from '../../config.json'


var API_URL = config.API_URL


export const loginAction = (payload) =>{
	return  function(dispatch){
		try{
			

			return axios.post(`${API_URL}/login`, payload, {}).then( (res) =>{


                dispatch({
                    type: ALERT,
                    payload: {
                        statusCode : 200,
                        message : `Logged IN successfully`,
                        data:res.data
                    }
                })

                dispatch({
                    type: LOGIN,
                    payload: {
                       data:res.data,
                       statusCode:200,
                    }
                })

                return true
            }).catch( (err)=>{

            	
                return dispatch({
                    type: ALERT,
                    payload:{
						statusCode:401,
						message:err.response.data.message
					}
                });
            })

		}catch(err){
			return dispatch({
				type: ALERT,
				payload:{
					statusCode:401,
					message:"Invalid Credentials"
				}
			})
		}
	}
}
export const logoutAction = () =>{
	return  function(dispatch){
		 dispatch({
	        type: ALERT,
	        payload: {
	            statusCode : 200,
	            message : `Logged Out successfully`,
	            data:null
	        }
	    })

	    dispatch({
	        type: LOGIN,
	        payload: {
	        	 data:null,
	        }
	    })

	    return true
	}
}

export const profileAction = (payload) =>{
	return  function(dispatch){
		try{
			const config = {
			    headers: { Authorization: "jwt "+payload.token }
			};
			
			
			return axios.post(`${API_URL}/profile/update`, payload, config).then( (res) =>{

				
                dispatch({
                    type: ALERT,
                    payload: {
                        statusCode : 200,
                        message : `Profile Update successfully`,
                        data:res.data
                    }
                })

                res.data.token = payload.token;
                dispatch({
                    type: LOGIN,
                    payload: {
                       data:res.data,
                       statusCode:200,
                    }
                })

                return true
            }).catch( (err)=>{

            	
                return dispatch({
                    type: ALERT,
                    payload:{
						statusCode:401,
						message:err.response.data.message
					}
                });
            })

		}catch(err){
			return dispatch({
				type: ALERT,
				payload:{
					statusCode:401,
					message:"Invalid Credentials"
				}
			})
		}
	}
}

export const updateEmail = (payload) =>{
	return  function(dispatch){
		try{
			const config = {
			    headers: { Authorization: "jwt "+payload.token }
			};
			
			
			return axios.post(`${API_URL}/profile/change_email`, payload, config).then( (res) =>{

				
                dispatch({
                    type: ALERT,
                    payload: {
                        statusCode : 200,
                        message : `Email Updated successfully`,
                        data:res.data
                    }
                })

              
                res.data.token = payload.token;
                dispatch({
                    type: LOGIN,
                    payload: {
                       data:res.data,
                       statusCode:200,
                    }
                })
                

                return true
            }).catch( (err)=>{
            	
            	console.log(err)
                return dispatch({
                    type: ALERT,
                    payload:{
						statusCode:401,
						message:err.response.data.message
					}
                });
            })

		}catch(err){
			
			return dispatch({
				type: ALERT,
				payload:{
					statusCode:401,
					message:"Something Went wrong"
				}
			})
		}
	}
}


export const forgotPassworAction = (payload) =>{
	return  function(dispatch){

		
		try{
			const config = {};
			
			
			return axios.post(`${API_URL}/forgot_password`, payload, config).then( (res) =>{

				
                dispatch({
                    type: ALERT,
                    payload: {
                        statusCode : 200,
                        message : `Password Updated successfully`,
                        data:res.data
                    }
                })


                return true
            }).catch( (err)=>{
            	
            	
                return dispatch({
                    type: ALERT,
                    payload:{
						statusCode:401,
						message:err.response.data.message
					}
                });
            })

		}catch(err){
			
			return dispatch({
				type: ALERT,
				payload:{
					statusCode:401,
					message:"Something Went wrong"
				}
			})
		}
	}
}

export const updatePhone = (payload) =>{
	return  function(dispatch){

		
		try{
			const config = {
			    headers: { Authorization: "jwt "+payload.token }
			};
			
			
			return axios.post(`${API_URL}/profile/change_phone`, payload, config).then( (res) =>{

				
                dispatch({
                    type: ALERT,
                    payload: {
                        statusCode : 200,
                        message : `Phone Updated successfully`,
                        data:res.data
                    }
                })

              
                res.data.token = payload.token;
                dispatch({
                    type: LOGIN,
                    payload: {
                       data:res.data,
                       statusCode:200,
                    }
                })
                

                return true
            }).catch( (err)=>{
            	
            	
                return dispatch({
                    type: ALERT,
                    payload:{
						statusCode:401,
						message:err.response.data.message
					}
                });
            })

		}catch(err){
			
			return dispatch({
				type: ALERT,
				payload:{
					statusCode:401,
					message:"Something Went wrong"
				}
			})
		}
	}
}

export const updatePassword = (payload) =>{
	return  function(dispatch){

		
		try{
			const config = {
			    headers: { Authorization: "jwt "+payload.token }
			};
			
			
			return axios.post(`${API_URL}/profile/change_password`, payload, config).then( (res) =>{

				
                dispatch({
                    type: ALERT,
                    payload: {
                        statusCode : 200,
                        message : `Password Updated successfully`,
                        data:res.data
                    }
                })

              

                return true
            }).catch( (err)=>{
            	
            	
                return dispatch({
                    type: ALERT,
                    payload:{
						statusCode:401,
						message:err.response.data.message
					}
                });
            })

		}catch(err){
			
			return dispatch({
				type: ALERT,
				payload:{
					statusCode:401,
					message:"Something Went wrong"
				}
			})
		}
	}
}

export const register = (payload) =>{
	return  function(dispatch){

		
		try{
			
			
			return axios.post(`${API_URL}/signup`, payload, {}).then( (res) =>{

				
                dispatch({
                    type: ALERT,
                    payload: {
                        statusCode : 200,
                        message : `User has been registered successfully`,
                        data:res.data
                    }
                })

                return true
            }).catch( (err)=>{
            	
            	
                return dispatch({
                    type: ALERT,
                    payload:{
						statusCode:401,
						message:err.response.data.message
					}
                });
            })

		}catch(err){
			
			return dispatch({
				type: ALERT,
				payload:{
					statusCode:401,
					message:"Something Went wrong"
				}
			})
		}
	}
}

export const otpAction = (payload) =>{
	return  function(dispatch){

		
		try{
			
			
			return axios.post(`${API_URL}/sendOtp`, payload, {}).then( (res) =>{

				
                dispatch({
                    type: ALERT,
                    payload: {
                        statusCode : 200,
                        message : `Otp has been send to your mobile number`,
                        data:res.data
                    }
                })

                return true
            }).catch( (err)=>{
            	
            	
                return dispatch({
                    type: ALERT,
                    payload:{
						statusCode:401,
						message:err.response.data.message
					}
                });
            })

		}catch(err){
			
			return dispatch({
				type: ALERT,
				payload:{
					statusCode:401,
					message:"Something Went wrong"
				}
			})
		}
	}
}

export const addToFavourite = (payload) =>{
	return  function(dispatch){
		try{
			const config = {
			    headers: { Authorization: "jwt "+payload.token }
			};
			
			
			return axios.post(`${API_URL}/favourite/add`, payload, config).then( (res) =>{

				
                dispatch({
                    type: ALERT,
                    payload: {
                        statusCode : 200,
                        message : `Property added to favourites`,
                        data:res.data
                    }
                })
                
                return true
            }).catch( (err)=>{

            	
                return dispatch({
                    type: ALERT,
                    payload:{
						statusCode:401,
						message:err.response.data.message
					}
                });
            })

		}catch(err){
			return dispatch({
				type: ALERT,
				payload:{
					statusCode:401,
					message:"Invalid Credentials"
				}
			})
		}
	}
}
export const removeFromFavourite = (payload) =>{
	return  function(dispatch){
		try{
			const config = {
			    headers: { Authorization: "jwt "+payload.token }
			};
			
			
			return axios.post(`${API_URL}/favourite/remove`, payload, config).then( (res) =>{

				
                dispatch({
                    type: ALERT,
                    payload: {
                        statusCode : 200,
                        message : `Property removed from favourites`,
                        data:res.data
                    }
                })
                
                return true
            }).catch( (err)=>{

            	
                return dispatch({
                    type: ALERT,
                    payload:{
						statusCode:401,
						message:err.response.data.message
					}
                });
            })

		}catch(err){
			return dispatch({
				type: ALERT,
				payload:{
					statusCode:401,
					message:"Invalid Credentials"
				}
			})
		}
	}
}

export const addToCart = (payload) =>{
	return  function(dispatch){
		dispatch({
	        type: ALERT,
	        payload: {
	            statusCode : 200,
	            message : `Room Added Into cart.`,
	            data:null
	        }
	    })

	    dispatch({
	        type: ADD_CART,
	        payload: payload
	    })

	    return true
	}
}


export const removeFromCart = (payload) =>{
	return  function(dispatch){
		dispatch({
	        type: ALERT,
	        payload: {
	            statusCode : 200,
	            message : `Room removed from cart.`,
	            data:null
	        }
	    })

	    dispatch({
	        type: REMOVE_CART,
	        payload: payload
	    })

	    return true
	}
}




export const emptyCart = () =>{
	return  function(dispatch){
		
	    dispatch({
	        type: EMPTY_CART,
	        payload: []
	    })

	    return true
	}
}


export const addCoupon = (payload) =>{
	return  function(dispatch){
		dispatch({
	        type: ALERT,
	        payload: {
	            statusCode : 200,
	            message : `Coupon has been applied`,
	            data:null
	        }
	    })

	    dispatch({
	        type: COUPON_ADD,
	        payload: payload
	    })

	    return true
	}
}
