import {createStore , applyMiddleware} from  'redux';
import rootReducer from "./redux/reducers/index";
import thunk from "redux-thunk" 
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from 'redux';
/*import { persistStore, persistReducer } from "redux-persist";

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;*/


/*import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

const loggerMiddleware = createLogger();

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
);
*/
import logger from 'redux-logger';
const persistConfig = {
  key: 'authType',
  storage: storage,
  whitelist: ['user'] // which reducer want to store

};





const pReducer = persistReducer(persistConfig, rootReducer);
///const middleware = applyMiddleware(thunk, logger);
const middleware = applyMiddleware(thunk);
const store = createStore(pReducer, middleware);
const persistor = persistStore(store);
export { persistor, store };