import React , {useState} from 'react'
import { Link } from 'react-router-dom'
//import { Image ,Tab} from 'react-bootstrap';
import DatePicker from 'react-datetime';
import moment from 'moment';
import Autocomplete from "react-google-autocomplete";
import HotelSearch from './HotelSearch'
import ApartmentSearch from './ApartmentSearch'
import HouseSearch from './HouseSearch'
import BestValue from './BestValue'

export default function HomepageSearch() {

    const [checkIn,setCheckIn] = useState();
    const [checkOut,setCheckOut] = useState();

    const [currenttab,setCurrenttab] = useState('1');
    const today = moment().subtract(1, 'days');
    const disableFutureDt = current => {
      return current.isAfter(today)
    }

    return (
        <>
        <section className="hotel-page">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 hotel_form1">
                        <form action="">
                            <div className="hotel_tab">
                                <ul className="nav nav-pills nav-justified" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation" onClick={()=>setCurrenttab('1')} >
                                        <a className={"nav-link " + (currenttab==='1' ? 'active' : '') } id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Hotels</a>
                                    </li>
                                    <li className="nav-item" role="presentation" onClick={()=>setCurrenttab('2')}>
                                        <a className={"nav-link " + (currenttab==='2' ? 'active' : '') } id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Apartment/Flat</a>
                                    </li>
                                    <li className="nav-item" role="presentation" onClick={()=>setCurrenttab('3')}>
                                        <a className={"nav-link " + (currenttab==='3' ? 'active' : '') } id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">House</a>
                                    </li>
                                </ul>
                            </div>

                            <div className="tab-content" id="pills-tabContent">
                                {currenttab==="1" && (
                                <div className="tab-pane1 fade show active" id="pills-home1" role="tabpanel" aria-labelledby="pills-home-tab">
                                
                                   <HotelSearch />
                                </div>
                                )}
                                {currenttab==="2" && (
                                <div className="tab-pane1 fade show active" id="pills-profile1" role="tabpanel" aria-labelledby="pills-profile-tab">
                                   <ApartmentSearch />
                                </div>
                               
                               )}
                                {currenttab==="3" && (
                               <div className="tab-pane1 fade show active" id="pills-contact1" role="tabpanel" aria-labelledby="pills-contact-tab">
                                   <HouseSearch />

                                </div>
                              )}
                              </div>


                        </form>
                    </div>
                </div>
            </div>


        </section>
        <BestValue currenttab={currenttab} />    
        </>
        
    )
}
