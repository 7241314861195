import React from 'react';
//import clsx from 'clsx';
//import { makeStyles, createStyles } from '@material-ui/core/styles';
//import Snackbar from '@material-ui/core/Snackbar';
//import SnackbarContent from '@material-ui/core/SnackbarContent';
//import IconButton from '@material-ui/core/IconButton';
//import CloseIcon from '@material-ui/icons/Close';
//import CheckCircleIcon from '@material-ui/icons/CheckCircle';
//import ErrorIcon from '@material-ui/icons/Error';
//import { green } from '@material-ui/core/colors';
import { useSelector } from "react-redux";
//import { useActions } from "../redux/actions";
//import * as Actions from "../redux/actions/auth";
import {NotificationContainer, NotificationManager} from 'react-notifications';
/*const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon
};*/

/*const useStyles = makeStyles((theme) =>
  createStyles({
    success: {
      backgroundColor: green[600],
        color:'#FFFFFF',
    },
    error: {
      backgroundColor: theme.palette.error.dark,
        color:'#FFFFFF',
    },
    icon: {
      fontSize: 20,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);*/

export default function AlertContainer() {
  //const authActions = useActions(Actions);
  const props = useSelector((state) => state.alert);
  const { message, statusCode } = props;

  //console.log(props,'authActions');
  if(props.statusCode===200){
    //NotificationManager.success('Success message', props.message);
  }else{
   // NotificationManager.error('Error message', props.message);
  }
  //const openState = !!message;

  //const classes = useStyles();
  //const [alert, setAlert] = React.useState(openState);

  React.useEffect(() => {
     
      if(message){
        if(props.statusCode===200){      
        NotificationManager.success('Success message', props.message);
        }else{
        NotificationManager.error('Error message', props.message);
        }
      }              
  });

 /* let variant = 'error';
  if (statusCode === 200) {
    variant = 'success';
  }*/

  if (!statusCode) {
    return <React.Fragment />;
  }

  
  return (
    <>
     <NotificationContainer/>
    </>
  )
}
