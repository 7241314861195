//import {Image } from 'react-bootstrap';
import { useState,useRef, useEffect } from "react";

//import { Link, useLocation } from 'react-router-dom'
import HomepageSearch from './partials/HomepageSearch'
//import BestValue from './partials/BestValue'
import HotelWords from './partials/HotelWords'
import LaunchingSoon from './partials/LaunchingSoon'
import HotelTravelSignUpPartner from './partials/HotelTravelSignUpPartner'

import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import DatePicker from 'react-datetime';



function Home() {
        
    const [dt, setDt] = useState(moment());
    
    useEffect(() => {
       document.title = "Idowaz - Home"
    }, []);


    return (
  
       <>

      
        <section className="hotel-banner">
            <h1>Idowaz Hotels & Travel</h1>
            <p>Discover amazing deals on over 2 million Hotels and Accommodations Worldwide</p>

           {/* <div className="container">
                <div className="search-box">
                    <form action="#">
                        <div className="search-input onlysearch line"><i className="fas fa-search bficon"></i>
                            <div className="form-group mb-0"><input type="text" placeholder="What are you looking for?" className="form-control" /></div>
                        </div>
                        <div className="search-input line"><i className="fas fa-calendar-alt bficon"></i>
                            <DatePicker
                                value={dt}
                                onChange={val => setDt(val)}
                              />
                        </div>
                        <div className="search-input"><i className="fas fa-calendar-alt bficon"></i>
                        <DatePicker
                            value={dt}
                            onChange={val => setDt(val)}
                          />
                        </div>
                        <div className="search-btn"><button type="submit" className="btn search_service">Search</button></div>
                    </form>
                </div>

            </div>
        */}
        </section>

        <HomepageSearch />
        
        <HotelTravelSignUpPartner />        
        <HotelWords />        

        <section className="t3-map">
            <div style={{width: "100%", height: "300px"}}>s</div>
        </section>

      


    </>
  );

}

export default Home;
