import React ,{useState,useEffect} from 'react'
import { Image } from 'react-bootstrap';
import { Link , useParams} from 'react-router-dom'
//import {service} from '../services.js'
import { useNavigate } from "react-router-dom";

import { NotificationManager} from 'react-notifications';
import {useActions} from "../../redux/actions";
import * as authActions from "../../redux/actions/auth";
import {  useSelector  } from "react-redux";
import {service} from '../../services.js' 

export default function BookingForm(props) {

   const user = useSelector((state)=> state.user)
   const userDetail = user&&user.data?user.data.data:{};
   console.log(userDetail)
   const [first_name,setFirstName] = useState(userDetail?userDetail.first_name:'')
   const [last_name,setLastName] = useState(userDetail?userDetail.last_name:'')
   const [email,setEmail] = useState(userDetail?userDetail.email:'')
   const [phone,setPhone] = useState(userDetail?userDetail.phone_number:'')
   const [request,setRequest] = useState('')

   const [first_nameErr,setFirstNameErr] = useState(false)
   const [last_nameErr,setLastNameErr] = useState(false)
   const [emailErr,setEmailErr] = useState(false)
   const [phoneErr,setPhoneErr] = useState(false)
   const [requestErr,setRequestErr] = useState(false)

   const [submitted,setSubmitted] = useState(false)

   const navigate = useNavigate();
 
   const cart = useSelector((state)=> state.cart)
   const applied_coupon = useSelector((state)=> state.coupon)
   const {emptyCart,addCoupon} = useActions(authActions)
   const [coupon,setCoupon] = useState(applied_coupon?applied_coupon.coupon_code:'')
   const [couponDesc,setCouponDesc] = useState(applied_coupon?applied_coupon.description:'')
   console.log(applied_coupon,'applied_coupon')
   


   const checkout = async (payload) => {
        
        
        var d = await service.postAuthRequest('create-checkout-session',payload)  
     
        if(d.status===200){
            window.location.href =d.data.data
        }
    }

   const handleSubmit = (e)=> {
     e.preventDefault();

        if(!user || !user.data){
            NotificationManager.error('Error message', "You have to login first");
            return
        }

        var err = false

        if(!first_name){
            err = true
            setFirstNameErr(true)
        }
        if(!last_name){
            err = true
            setLastNameErr(true)
        }
        if(!email){
            err = true
            setEmailErr(true)
        }
        if(!phone){
            err = true
            setPhoneErr(true)
        }
        if(!request){
            err = true
            setRequestErr(true)
        }

        if(err){
            return false;
        }

        var ccode= "";
        if(applied_coupon){
            ccode = applied_coupon.coupon_code
        }

        

        let payload = {
            first_name:first_name,
            last_name:last_name,
            email:email,
            phone:phone,
            request:request,
            coupon:ccode,        
            cart:cart,
            token:user.data.token,
            
        }

        setSubmitted(true)
     
       
        checkout(payload)
        
       
    }

    const openLogin = () =>{
        document.getElementById('login').click()
    }
    const applyCoupon =async  () => {
        if(!coupon){
            NotificationManager.error('Error message', "Please enter Couon Code");
             return;
        }

        let payload = {
            "coupon":coupon
        }

        var d = await service.postAuthRequest('verify-coupon',payload)  
   
        if(d.status===200){            
            var couponDetail = d.data.data;
           
            console.log(d.data.data,'coupon data')
            addCoupon(couponDetail)
            window.location.reload(false);
            
        }else{
            localStorage.removeItem('coupon');
            NotificationManager.error('Error message', "Coupon not found or expired!");
        }

    }
    useEffect(() => {   
        setFirstName(userDetail?userDetail.first_name:'')
        setLastName(userDetail?userDetail.last_name:'')
        setEmail(userDetail?userDetail.email:'')
        setPhone(userDetail?userDetail.phone_number:'')
    },[userDetail]);
    return (
       <>
        <div class="col-lg-7">
            <div class="guest-detail">

                <h2>Information</h2>
                   <br /><br />
                {!user || !user.data ? (
                    <div className="alert alert-warning">
                 
                    <center><h4>You must have to <span onClick={openLogin} style={{cursor:'pointer','color':"#0d6efd"}}> login</span> before booking</h4></center>
                    </div>
                    ): (
                <form onSubmit={handleSubmit}>
                    <div class="form-group">
                        <div class="row">
                            <div class="col first-name">
                                <label>first name</label>
                                <input type="text" id="firstName" class="form-control" placeholder="First name" value={first_name} onChange={(e)=>{setFirstName(e.target.value)
                                    setFirstNameErr(false)
                                }} />
                                {first_nameErr && <p className="field-err">Please enter first name</p>}
                            </div>
                            <div class="col">
                                <label>last name</label>
                                <input type="text" id="lastName" class="form-control" placeholder="Last name" value={last_name} onChange={(e)=>{setLastName(e.target.value)
                                 setLastNameErr(false)
                                }} />
                            
                                {last_nameErr && <p className="field-err">Please enter last name</p>}
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Email address</label>
                        <input type="email" class="form-control" placeholder="Enter email" value={email} onChange={(e)=>{setEmail(e.target.value)
                         setEmailErr(false)
                        } }/>
                        <small id="emailHelp" class="form-text text-muted">Booking confirmation will be sent to
                            this email ID.</small>
                            {emailErr && <p className="field-err">Please enter email</p>}
                            
                    </div>
                    <div class="form-group">
                        <label>contact info</label>
                        <input id="mobile-no" type="tel" class="form-control" value={phone} onChange={(e)=>{setPhone(e.target.value)
                        setPhoneErr(false)
                        } }/>
                        {phoneErr && <p className="field-err">Please enter phone number</p>}
                    </div>
                    <div class="form-group">
                        <label for="exampleFormControlTextarea1">special request</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="" onChange={(e)=>{setRequest(e.target.value)
                        setRequestErr(false)
                        } }>{request}</textarea>
                        {requestErr && <p className="field-err">Please enter request note</p>}
                    </div>
                    <div class="form-group">
                        <label for="exampleFormControlTextarea1">have a coupon code?</label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Promo Code" value={coupon} onChange={(e)=>setCoupon(e.target.value)} />
                            <div class="input-group-prepend" onClick={applyCoupon} style={{"cursor":"pointer"}}>
                                <span class="input-group-text" >apply</span>
                            </div>
                        </div>
                    </div>
                    <div class="submit-btn">
                        {submitted ?
                        <button type="submit" disabled class="btn btn-solid" >Redirecting..</button>
                        :
                        <button type="submit" class="btn btn-solid" >Pay Now</button>   
}
                    </div>
                </form>
                )}
            </div>
        </div>
       </>
    )
}
