import React from 'react'
//import {Image } from 'react-bootstrap';
export default function HotelTravelSignUpPartner() {
    return (
        <section className="hotel-sign">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8">
                    <div className="food-detail-panel text-center">
                        <h3 className="new-title "><span>Idowaz Hotels & Travel</span></h3>
                        <p>When you spend less on hotel stays, you’ll have more to spend on the things that really matter, creating unforgettable memories during your trip.</p>
                        <p>Browse the world’s top hotel booking services and find top-rated hotels at affordable prices right in the Idowaz app.</p>
                        <button className="btn btn-solid color1" type="button"> Sign up as partner </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}
