import React ,{useEffect, useState} from 'react'
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import {useActions} from "../../redux/actions";
import * as authActions from "../../redux/actions/auth";
import {  useSelector  } from "react-redux";
import { Modal} from 'react-bootstrap';
import moment from 'moment';
import DatePicker from 'react-datetime';
import { useNavigate } from "react-router-dom";

export default function Room(props) {

    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    const [checkIn,setCheckIn] = useState();
    const [checkOut,setCheckOut] = useState();
    const [rooms,setRooms] = useState(1);
    const [adults,setAdults] = useState('');

    const [checkInErr,setCheckInErr] = useState();
    const [checkOutErr,setCheckOutErr] = useState();
    const [roomsErr,setRoomsErr] = useState('');
    const [adultsErr,setAdultsErr] = useState('');

    const [childrens,setChildrens] = useState(0);
    const [childrensErr,setChildrensErr] = useState(false);

    const today = moment().subtract(1, 'days');
    const room = props.data

    const cart = useSelector((state)=> state.cart)

    console.log('cart',cart)
  
    const {addToCart} = useActions(authActions)


    useEffect(() => {   
           
    },[]);
    const disableFutureDt = current => {
      return current.isAfter(today)
    }
     const disableFutureDtCheckout = current => {
      return current.isAfter(checkIn)
    }
    
    const handleSubmit = (e)=> {
        e.preventDefault()
        var err = false;
        if(!checkIn){
           err = true
           setCheckInErr(true)
        }

        if(!checkIn){
          err = true
          setCheckOutErr(true)
        }

        if(!rooms || rooms===0){
          err = true
          setRoomsErr('Please enter rooms')
        }

        var avil_rooms = room.quantity-room.booked_room

        if(rooms>avil_rooms){
          err = true
          setRoomsErr("Only "+avil_rooms+" rooms are availaibe.")
        }

        if(!adults || adults<0){
          err = true
          setAdultsErr(true)
        }

        if(adults>room.adults){
          err = true
          setAdultsErr(true)
        }



        if(!childrens || childrens<0){
         // alert(childrens)
         if(childrens==0){

         }else{
          err = true
          setChildrensErr(true)
         }
        }

        if(childrens>room.childrens){
          err = true
          setChildrensErr(true)
        }


        if(err){
          return false
        }

       


        let payload = {
            room_id:room.id,
            detail:room,
            checkIn:checkIn,
            checkOut:checkOut,
            rooms:rooms,
            adults:adults,
            childrens:childrens
        }

      

        addToCart(payload);
        navigate("/hotel-booking");   
    }

    return (
        <>
              <tr>
                <td>
                    <h6 className="room-title">{room.title}</h6>
                    <Link to="#">

                      {room.images.length>0  ?
                            (<img src={room.images[0].image} className="img-fluid blur-up lazyload" alt="" />)
                            :
                            (<Image src={ require("../../assets/images/hotel/gallery/1.jpg")} className="img-fluid blur-up lazyload" alt="" />)
                        }

                      
                    </Link>
                </td>
                <td>
                    <div className="room-detail">
                        <div className="row">
                            <div className="col-6 p-0">
                                <h6>Amenities</h6>
                                <div className="facility-detail">
                                    <ul>
                                    {room.features.map((f,key)=>{
                                        return (<li key={key}><img src={f.detail.icon_path}
                                                className="img-fluid blur-up lazyload" alt="" />
                                            {f.detail.title}
                                        </li>)
                                    })}
                                        
                                       
                                    </ul>
                                </div>
                            </div>
                            {/*
                            <div className="col-6 p-0">
                                <h6>inclusion</h6>
                                <div className="facility-detail">
                                    <ul>
                                        <li><i className={room.smoking_allowed? 'fas fa-check':'fas fa-times'}></i> Smoking </li>
                                        <li><i className={room.breakfast_included? 'fas fa-check':'fas fa-times'}></i> Breakfast</li>
                                        <li><i className={room.cancellation? 'fas fa-check':'fas fa-times'}></i>non refundable</li>
                                    </ul>
                                </div>
                            </div>
                        */}
                             <div className="col-6 p-0">
                                 <h6>Room Availaibity count</h6>
                                  <div className="facility-detail">
                                    <ul>
                                        <li><i className=""></i> {room.quantity >1 ? room.quantity+ " Rooms":room.quantity +" Room"}   </li>
                                       
                                    </ul>
                                  </div>

                                  <h6>Adults count</h6>
                                  <div className="facility-detail">
                                    <ul>
                                        <li><i className=""></i> {room.adults} Adults  </li>
                                       
                                    </ul>
                                  </div>

                                  <h6>Children count</h6>
                                  <div className="facility-detail">
                                    <ul>
                                        <li><i className=""></i> {room.childrens} Children  </li>
                                       
                                    </ul>
                                  </div>
                             </div>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="price-details">
                        <div>
                            <h6><del>₦{room.original_price.toLocaleString('hi-IN')}</del></h6>
                            <h5>₦{room.discount_price.toLocaleString('hi-IN')}</h5>
                            <span>per night</span>
                            {room.quantity==room.booked_room ? 
                            <Link to="#"
                            className="btn btn-rounded btn-sm color1" >Booked</Link>
                            :
                            <Link to="#"
                                className="btn btn-rounded btn-sm color1" onClick={()=>setShow(true)}>book now</Link>
                          }
                                </div>
                    </div>
                </td>
            </tr>      

                            <Modal show={show} onHide={()=>setShow(false)} className=" edit-profile-modal">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Book Room - {room.title}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setShow(false)}></button>
                            </div>
                            <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                 <div className="form-group col-md-12">
                                    <label>Check In</label>
                                      <div className="input-group">
                                        <DatePicker
                                         inputProps={{"placeholder":"Check In","class":"form-control"}}
                                         value={checkIn}
                                         onChange={val => {setCheckIn(val)
                                         setCheckInErr(false)
                                         }}
                                        closeOnSelect={ true }
                                         isValidDate={disableFutureDt}
                                      />
                                          <div className="input-group-text"><i className="fas fa-calendar-alt"></i>
                                          </div>
                                      </div>
                    
                                       {checkInErr && <p className="field-err">Please select checkIn Date </p>}
                                    </div>
                                     <div className="form-group col-md-12">
                                     <label>Check Out</label>
                                      <div className="input-group">
                                      <DatePicker
                                        inputProps={{"placeholder":"Check Out"}}
                                          value={checkOut}
                                          onChange={val => {setCheckOut(val)
                                            setCheckOutErr(false)
                                          }}
                                          closeOnSelect={ true }
                                          isValidDate={disableFutureDtCheckout}
                                        /> 
                                        <div className="input-group-text"><i className="fas fa-calendar-alt"></i>
                                          </div>
                                       </div>
                                      
                                        {checkOutErr && <p className="field-err">Please select checkOut Date </p>}
                                      </div>
                                      <div className="form-group col-md-12">
                                      <label>How Many rooms?</label>
                                        <input type="number" name="rooms" 
                                        value={rooms}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                        onChange={e => {setRooms(e.target.value)
                                          setRoomsErr(false)
                                        }}
                                        className="form-control" placeholder="No of rooms" />
                                         {roomsErr && <p className="field-err">{roomsErr}</p>}
                                      </div>

                                      <div className="form-group col-md-12">
                                      <label>Adults</label>
                                        <input type="number" name="adults" 
                                        value={adults}
                                        min="0"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                        onChange={e => {
                                          setAdults(e.target.value)
                                          setAdultsErr(false)
                                        }}
                                        className="form-control" placeholder="No of Adults" />
                                         {adultsErr && <p className="field-err">Please enter adults (Max: {room.adults})</p>}
                                      </div>

                                      <div className="form-group col-md-12">
                                         <label>Childrens</label>
                                        <input type="number" name="childrens" 
                                        min="0"
                                        value={childrens}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                        onChange={e => {setChildrens(e.target.value)
                                          setChildrensErr(false)
                                          
                                        }}
                                        className="form-control" placeholder="No of childrens" />
                                       {childrensErr && <p className="field-err">Please enter childrens (Max: {room.childrens})</p>}

                                      </div>
                                   
                               
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={()=>setShow(false)}>Close</button>
                                <button type="submit" className="btn btn-solid">Book</button>
                            </div>
                        </form>
                </Modal>

        </>
    )
}
