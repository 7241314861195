import { ADD_CART  , REMOVE_CART, EMPTY_CART} from "../actionTypes";

const initialState = localStorage.getItem('cart')?JSON.parse(localStorage.getItem('cart')):[];
//const initialState = [];

const cart = (state = initialState, action) => {

  console.log(action.type,'action.type')
  switch (action.type) {
    
    case ADD_CART: {
     

      let exist = state.filter((item) => item.room_id == action.payload.room_id);

      let change_propery = state.filter((item) => item.detail.property_id == action.payload.detail.property_id);

     

      if(exist.length>0){
        state=  state.map((todo) => {
            if (todo.room_id === action.payload.room_id) {
              return {
                ...todo,
                item: action.payload,
              };
            }
            return todo;
          });

      }else{
        if(change_propery.length==0){
          state = [action.payload]  
        }else{
          state.push(action.payload);         
        }
        
        //return state;
      }
      const cart = JSON.stringify(state);

    localStorage.setItem('cart', cart);
    return state;
      
     }

     case REMOVE_CART: {

      console.log(action.payload,'actionactionaction')
       state= state.filter((item) => item.room_id !== action.payload.room_id);
        const cart = JSON.stringify(state);
        console.log(cart,'cartcart')
       localStorage.setItem('cart', cart);
        return state;
     
    }

    case EMPTY_CART:{
       localStorage.setItem('cart', null);
        return [];
    }

    default:
      return state;
  }
}

export default cart;