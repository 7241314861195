import React ,{useEffect, useState} from 'react'
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import {useActions} from "../../redux/actions";
import * as authActions from "../../redux/actions/auth";
import {  useSelector  } from "react-redux";
import {service} from '../../services.js' 
import { NotificationManager} from 'react-notifications';
import Hotel from './Hotel'



export default function FavoriteProperties(props) {


	const [properties, setproperties] = useState([]);
	
    const [pageCount, setPageCount] = useState(1);
    const [itemOffset, setItemOffset] = useState(5);
    const user = useSelector((state)=> state.user)
	useEffect(() => {   
            getFavProperties()
    },[]);

	const getFavProperties = async () => {
		 let payload = {
            token:user.data.token
        }

		var d = await service.getRequest('favourite/properties',payload)       
        if(d.status===200){

            setproperties(d.data.data)
            setPageCount(d.data.pagination.pageCount)
        }
	}

	 return (
        <>
        	<div class="tab-pane fade show active" id="favorite">
                                            <div class="dashboard-box">
                                                <div class="dashboard-title">
                                                    <h4>My Favorites  </h4>
                                                </div>
                                                <div class="dashboard-detail ratio3_2">
                                                      
                                                    <div class="product-wrapper-grid special-section grid-box">
                                                       
                                                        <div class="row trend">
                                                        {properties.map(function(object, i){
							                                return (
							                                	 <Hotel data={object.property} />
							                                )
							                            })}

                                                        {properties.length==0 ? (
                                                            <div className="alert alert-warning">You have not marked any properties as Favourite</div>
                                                            ) : null}

                                                            
                                                        </div>
                                                    </div>    
                                                      

                                                </div>

                                            </div>

                                        </div>
        </>
        )
	}