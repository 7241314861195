import React ,{useEffect, useState ,useRef} from 'react'
import { Image } from 'react-bootstrap';
import { Link,useParams ,useSearchParams,useLocation} from 'react-router-dom'
import {service} from '../services.js' 
import Hotel from './partials/Hotel'
import Filters from './partials/Filters'
import ReactPaginate from 'react-paginate';

export default function Hotels(props) {

    const [page, setPage] = useState(1);  
    const [selectedFeature,setSelectedFeature]  = useState([]);
    const [price_value, setPriceValue] = useState( { min: 0, max: 1000 });
    const [search, setSearch] = useState('');
    const [hotels, setHotel] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [itemOffset, setItemOffset] = useState(5);

    const s = useLocation().search;
    const lat = new URLSearchParams(s).get('lat');
    const lng = new URLSearchParams(s).get('lng');
    const adults = new URLSearchParams(s).get('adults');
    const childrens = new URLSearchParams(s).get('childrens');
    const rooms = new URLSearchParams(s).get('rooms');
    const type = new URLSearchParams(s).get('type');
    const address = new URLSearchParams(s).get('address');

    const setPrice = (val) =>{
        setPriceValue(val)
    }
    const saveFeatures = (isChecked,value) =>{
    
        if(isChecked){
            var newS= [...selectedFeature, value]
            setSelectedFeature(newS)
            
        }else{
            let index = selectedFeature.indexOf(value);
            selectedFeature.splice(index, 1);           
            setSelectedFeature(prev => prev.filter((el, i) => el.id !== value));
            
        }
        
    }

    const gethighestPrice = async () => {
         var d = await service.getRequest('getHighestPrice')  
       
        if(d.status===200){
            
            setPriceValue({min:0,max:d.data.data})       
        }
    }

     useEffect(() => {
       document.title = "Idowaz - Hotels"
       gethighestPrice()
    }, []);

    useEffect(() => {           

        var ptype="hotel";
        if(type && type!='' ){
            ptype = type
        }
   
        var d =  service.getRequest("properties?property_type="+ptype+"&page="+page+"&minPrice="+price_value.min+'&maxPrice='+price_value.max+'&features='+selectedFeature.join(',')+'&search='+search+'&lat='+lat+'&lng='+lng+'&rooms='+rooms+'&adults='+adults+'&childrens='+childrens).then( (res) =>{          
            setHotel(res.data.data)
            setPageCount(res.data.pagination.pageCount)
        })       
        
    },[page,price_value,selectedFeature,search]);


    
    return (
        <>
        <section className="breadcrumb-section pt-0">
            <div className="breadcrumb-content">
                <div>
                    <h2>hotels   {address ? 'in '+address:''}</h2>
                    <nav aria-label="breadcrumb" className="theme-breadcrumb">
                        
                            
                            {address && (
                                <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">hotels in {address}</li>
                            </ol>
                            )}
                            
                    </nav>
                </div>
            </div>
        </section>

        <section className="xs-section bg-inner">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="filter-panel">
                            <div className="left-filter">
                                <div className="respon-filter-btn">
                                    <h6> filter <i className="fas fa-sort-down"></i></h6>
                                    <span className="according-menu"></span>
                                </div>
                                <div className="filters respon-filter-content filter-button-group">
                                    <ul>
                                        <li className="active" data-filter="*">All</li>
                                        <li data-filter=".popular">popular</li>
                                        <li data-filter=".latest">latest</li>
                                        {/*<li data-filter=".trend">My Favorite</li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3">
                        <div className="left-sidebar">
                            <div className="back-btn"><i className="fas fa-arrow-left"></i> back</div>
                            <div className="search-bar">
                                <input type="text" placeholder="Search here.." name="search" onChange={(e)=>setSearch(e.target.value)}/>
                                <i className="fas fa-search"></i>
                            </div>

                            <div className="middle-part collection-collapse-block open">
                                <a href="#!" className="section-title collapse-block-title">
                                    <h5>latest filter</h5>
                                    <Image src={ require("../assets/images/icon/adjust.png")} className="img-fluid blur-up lazyload" alt="" />
                                </a>
                               <Filters  setPrice={setPrice} price_value={price_value} 
                               selectedFeature={selectedFeature}
                               saveFeatures={saveFeatures}
                               />

                            </div>

                        </div>
                    </div>

                    <div className="col-lg-9 ratio3_2">
                        <a href="#!" className="mobile-filter border-top-0">
                            <h5>Latest</h5>
                            <Image src={ require("../assets/images/icon/adjust.png")} className="img-fluid blur-up lazyload" alt="" />
                        </a>
                        <div className="product-wrapper-grid special-section grid-box">
                            <div className="row  content grid">
                        {hotels.length>0 ?
                             hotels.map(function(object, i){

                                return (<Hotel data={object} key={i} />)
                            })
                            : (<div className="no-data">No Hotels Found</div>)}
                                


                            </div>
                        </div>


                        


                        <nav aria-label="Page navigation example" className="pagination-section mt-0">

                         <ReactPaginate
                            breakLabel="..."
                            nextLabel="&raquo;"
                            onPageChange={(e)=>setPage(e.selected+1)}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel="&laquo;"
                            renderOnZeroPageCount={null}
                            className="pagination"
                          />

                               
                            </nav>


                    </div>

                </div>
            </div>
        </section>
        </>
    )
}
