import React ,{useState} from 'react';
import { Navbar ,Image,Modal} from 'react-bootstrap';
import { Link } from 'react-router-dom'
import * as authActions from "../redux/actions/auth";
import {useActions} from "../redux/actions";
import 'react-notifications/lib/notifications.css';
import { useNavigate } from "react-router-dom";
import {  useSelector  } from "react-redux";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { NotificationManager} from 'react-notifications';
import {service} from '../services.js'
import config from '../config.json'

import { useLocation } from 'react-router-dom'

var ADMIN_URL = config.ADMIN_URL
const Head = (props) => {

    const location = useLocation();
    console.log(location.pathname);

    const [user_type,setUserType ]= useState('user');
    const [show, setShow] = useState(false);
    const [showLogin, setShowLogin] = useState(true);
    const [showPwd, setShowPwd] = useState(false);
    const {loginAction,logoutAction,forgotPassworAction,otpAction} = useActions(authActions)
    const navigate = useNavigate();
    const [email,setEmail ]= useState('');
    const [password,setPassword ]= useState('');
    const [emailErr,setEmailErr ]= useState(false);
    const [passwordErr,setPasswordErr ]= useState(false);
    //foegot pwd
    const [forgot_email,setForgotEmail] = useState('');
    const [forgot_phone,setForgotPhone] = useState('');
    const [forgot_otp,setForgotOtp] = useState('');
    const [forgot_password,setForgotPassword] = useState('');
    const [forgot_confirm_password,setForgotConfirmPassword] = useState('');

    const [forgot_emailErr,setForgotEmailErr] = useState(false);
    const [forgot_phoneErr,setForgotPhoneErr] = useState(false);
    const [forgot_otpErr,setForgotOtpErr] = useState(false);
    const [forgot_passwordErr,setForgotPasswordErr] = useState(false);
    const [forgot_confirm_passwordErr,setForgotConfirmPasswordErr] = useState(false);

    //header submenu
    const [submenu, setSubMenu] = useState(false);
    //stat  e
    const user = useSelector((state)=> state.user)
    const cart = useSelector((state)=> state.cart)
    
   
   
    const passwordmodal = () =>{
        setShowLogin(false)
        setShowPwd(true)
    }

    const loginmodal = () =>{

        setShowPwd(false)
        setShowLogin(true)
        setShow(true)
    }

    const opensignup= () => {
        setShowLogin(false)
        setShow(false)
        navigate("/signup");   
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        var err= false;
        if(!email){
            setEmailErr(true);
            err= true;
        }

        if(!password){
            setPasswordErr(true);
            err= true;
        }
        if(err){
            return false;
        }

        
       
        let payload ={
            email:email,
            password:password,
            user_type:user_type
        }
        loginAction(payload).then(async (res)=>{   
            if(res ===true){     
               setSubMenu(false)
               setShow(false)
               navigate("/dashboard");   
            }       
        });
   }
    const sendOtp = (e) =>{
        let payload ={ 
            phone_number:forgot_phone,
            type:'forgot_password' 
        
        }
        otpAction(payload).then(async (res)=>{   

            console.log(res)
             if(res ===true){     
              
               
            }  
        });
    }

   const handleSubmitForgotPwd =(e) =>{

        e.preventDefault()

        setForgotEmailErr(false)
        setForgotPasswordErr(false)
        setForgotConfirmPasswordErr(false)
        setForgotPhoneErr(false)
        setForgotOtpErr(false)

        var err= false;
        if(!forgot_email){
            err= true;
            setForgotEmailErr(true)
        }
       
        if(!forgot_phone){
            err= true;
            setForgotPhoneErr(true)
        }
        if(!forgot_otp){
            err= true;
            setForgotOtpErr(true)
        }
        if(!forgot_password){
            err= true;
            setForgotPasswordErr(true)
        }
        if(!forgot_confirm_password){
            err= true;
            setForgotConfirmPasswordErr(true)
        }
        if(forgot_confirm_password!= forgot_password){
            err= true;
            setForgotConfirmPasswordErr(true)
        }

        if(err){
            return false;
        }

        let payload ={
            email:forgot_email,
            password:forgot_password,
            confirm_password:forgot_confirm_password,
            phone_number:forgot_phone,
            otp:forgot_otp,
            user_type:user_type
        }
        forgotPassworAction(payload).then(async (res)=>{   
            if(res ===true){     
               setSubMenu(false)
               setShow(false)
               setShowPwd(false)
               setShowLogin(true)
               navigate("/");   
            }       
        });
   }
   const logout = () => {
        logoutAction();
        setSubMenu(false)
        navigate("/home");   
   }

   const goToAdmin = async () => {
    if(user  &&  user.data){
        var cuser= user.data.data
        console.log('user_id',cuser)
        //  e.preventDefault()
       
        let payload ={
            user_id:cuser.id,
             token:user.data.token
          
        }
        var d = await service.postAuthRequest('admin-login',payload)  

        if(d.status===200){            
            window.location.href = ADMIN_URL
        }else{
            NotificationManager.error('Error message', 'Please login first');
        }


    }
   }

    return (
        <>
       
        <header className="header">
            <div className="header-fixed">
                <Navbar className="navbar navbar-expand-lg header-nav">
                    <div className="navbar-header">
                        <Link id="mobile_btn" to="#!">
                            <span className="bar-icon">
                                <span></span>
                                <span></span>
                                <span></span>
                            </span>
                        </Link>
                        <Link to="/" className="navbar-brand logo">

                          

                            <Image src={require("../assets/images/new/logo.png")} className="img-fluid" alt="Logo" />
                        </Link>
                        <Link to="/" className="navbar-brand logo-small">
                            <img src={require("../assets/images/new/logo-icon.png")} className="img-fluid" alt="Logo" />
                        </Link>
                    </div>
                    <div className="main-menu-wrapper">
                        <div className="menu-header">
                            <Link to="/" className="menu-logo">
                                <img src={require("../assets/images/new/logo.png")} className="img-fluid" alt="Logo" />
                            </Link>
                            <Link id="menu_close" className="menu-close" to="#!"> <i className="fas fa-times"></i></Link>
                        </div>
                        <ul className="main-nav">
                            <li className={location && location.pathname=="/"?"active":""}>
                                <Link to="/">Home</Link>
                            </li>
                            <li className="">
                                <a href="https://idowaz.com/about-us" target="_blank">About Us</a>
                            </li>


                            {user  &&  user.data ? null:
                            <li className={location && location.pathname=="/partner-signup"?"active":""}>
                                {/* <a href="#" onClick={()=>goToAdmin()}>Become a Partner</a> */}
                                <Link to="/partner-signup">Become a Partner</Link>
                            </li>
}
                                 
                            {cart.length>0?(
                            <li className={location && location.pathname=="/hotel-booking"?"active":""}>
                                <Link to="/hotel-booking">Checkout</Link>
                            </li>
                                ):''}                      
                            <li className="select_language">
                                <div id="google_translate_element"></div>
                            </li>

                        </ul>
                    </div>

                    <ul className="nav header-navbar-rht">

                    
                    {user  &&  user.data ? (
                        <li className="nav-item dropdown has-arrow logged-item">
                            <Link to="#" className={"dropdown-toggle nav-link "+(submenu?'show':"")} data-bs-toggle="dropdown" aria-expanded="false" onClick={()=>setSubMenu(!submenu)}>
                                <span className="user-img">
                                {user.data.data.profile_pic ?
                                (<img src={user.data.data.profile_pic} className="rounded-circle" alt="" /> )                                               
                            :
                                (<Image src={ require("../assets/images/avtar/1.png")} className="rounded-circle" alt="" /> )                                               
                            }
  
                                </span>
                            </Link>
                            <div className={"dropdown-menu dropdown-menu-right "+(submenu?'show':"")} style={{marginTop:"234px"}}>
                                <div className="user-header">
                                    <div className="avatar avatar-sm">

                                    {user.data.data.profile_pic ?
                                (<img src={user.data.data.profile_pic} className="avatar-img rounded-circle" alt="" /> )                                               
                            :
                                (<img className="avatar-img rounded-circle" src={require("../assets/images/avtar/1.png")} alt="" /> )                                               
                            }
                            
                                        
                                    </div>
                                    <div className="user-text">
                                        <h6>{user.data.data.first_name} {user.data.data.last_name}</h6>
                                        <p className="text-muted mb-0">User</p>
                                    </div>
                                </div>
                                <Link className="dropdown-item" to="/dashboard">Profile</Link>
                                <Link className="dropdown-item" to="#" onClick={()=>logout()}>Logout</Link>
                            </div>
                        </li>
                     ) : (
                     <>
                      <li className="nav-item">
                            <a className="nav-link header-login" href="#!" data-bs-toggle="modal" data-bs-target="#login" onClick={()=>loginmodal()} id="login">Login</a>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link header-login" to="/signup" >Signup</Link>
                        </li>
                    </>
                     )}
                    </ul>

                </Navbar>
            </div>
        </header>
        
        <Modal show={show} onHide={()=>setShow(false)} className="edit-profile-modal" >
                    {showLogin && (
                    <div class="signin_blk">
                        <div class="modal-header">
                            <h5 class="modal-title" id="signinH5">Login Idowaz</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setShow(false)}></button>
                        </div>      
                        <div class="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div class="mb-3">
                                          {/*
                                        <label for="" class="form-label">Sign in as <span class="text-danger">*</span></label>
                                  
                                        <div className="radio">
                                            

                                            <label >
                                            <input className='ml-5' type="radio" value="user" 
                                                            checked={user_type === 'user'} 
                                                            onChange={(e) => setUserType('user') } />
                                            User 
                                            </label>

                                            <label className='ml-15'>
                                            <input className='ml-5' type="radio" value="partner" 
                                                            checked={user_type === 'partner'} 
                                                            onChange={(e) => setUserType('partner') } />
                                                            Partner
                                            </label>
                                        </div>
                    */}                                    
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label">Email Address <span class="text-danger">*</span></label>
                                    <input type="email" class="form-control" placeholder="Email Address" value={email} onChange={(e) => {setEmail(e.target.value)
                                    setEmailErr(false)
                                    }} />
                                    {emailErr && <p className="field-err">Please enter Email </p>}


                                </div>
                                
                                <div class="mb-3">
                                    <label for="exampleInputPassword1" class="form-label">Password <span class="text-danger">*</span></label>
                                    <input type="password" class="form-control" placeholder="Password" value={password} onChange={(e) => {setPassword(e.target.value)
                                    setPasswordErr(false)
                                    }} />
                                    {passwordErr && <p className="field-err">Please enter Password </p>}
                                </div>
                                
                                <div class="mb-3 text-end">
                                    <a class="forgot_password" href="#!" onClick={()=>passwordmodal()}>Forgot password?</a>
                                </div>

                                <button type="submit" class="btn btn-solid color1">LogIn</button>
                            </form>
                        </div>

                        <div class="modal-footer">
                            <div class="d-flex justify-content-center" style={{width:"100%"}}>
                                <span>Login as partner?   
                                    <a href="https://idowazbooking.com:3005/" className="makeAnchor">&nbsp; Click Here
                                    </a>
                                    </span>
                                    <br></br>
                            </div>
                            <div class="d-flex justify-content-center">
                                <span>Already have an account? <b onClick={opensignup} className="makeAnchor">Sign Up</b></span>
                            </div>
                        </div>
                    </div>
                    )}
                    {showPwd && (
                    <div class="forgotpassword_blk">
                        <div class="modal-header">
                            <h5 class="modal-title" id="signinH5">Forgot Password</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form onSubmit={handleSubmitForgotPwd}>
                            <div className="row">
                                    <div class="mb-3">
                                        <label for="" class="form-label">Sign up as <span class="text-danger">*</span></label>
                                    
                                        <div className="radio">
                                            

                                            <label>
                                            <input type="radio" value="user" 
                                                            checked={user_type === 'user'} 
                                                            onChange={(e) => setUserType('user') } />
                                            User 
                                            </label>
                                            <label>
                                            <input type="radio" value="partner" 
                                                            checked={user_type === 'partner'} 
                                                            onChange={(e) => setUserType('partner') } />
                                                            Partner
                                            </label>
                                        </div>
                                    
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="" class="form-label">Email ID </label>
                                    <input type="email" class="form-control" placeholder="" value={forgot_email} onChange={(e)=>{setForgotEmail(e.target.value)
                                    setForgotEmailErr(false)
                                    }} />
                                    {forgot_emailErr && <p className="field-err">Please enter Email </p>}
                                </div>
                                <div class="row">
                                    <div class="col-md-8 mb-3">
                                        <label for="" class="form-label">Contact Number</label>
                                         <PhoneInput
                                             country={'in'}
                                              placeholder="Enter phone number"
                                              value={forgot_phone}
                                            
                                              onChange={(value) => {
                                 
                                                setForgotPhone(value)
                                                setForgotPhoneErr(false)
                                            }} 
                                            />
                                        
                                        <a class="get-otp" href="#" onClick={(e)=>sendOtp(e)}>Get OTP</a>
                                        {forgot_phoneErr && <p className="field-err">Please enter contact number </p>}
                                    </div>
                                    <div class="col-md-4 mb-3">
                                        <label for="" class="form-label">OTP </label>

                                        <input type="text" class="form-control" placeholder="" value={forgot_otp} onChange={(e)=>{setForgotOtp(e.target.value)
                                        setForgotOtpErr(false)
                                        } }/>
                                        {forgot_otpErr && <p className="field-err">Please enter OTP </p>}
                                        
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 mb-3">
                                        <label class="form-label">New Password </label>
                                        <input type="password" class="form-control" placeholder="" value={forgot_password} onChange={(e)=>{setForgotPassword(e.target.value)
                                        setForgotPasswordErr(false)
                                        }} />
                                        {forgot_passwordErr && <p className="field-err">Please enter password </p>}
                                    </div>
                                    <div class="col-md-6 mb-3">
                                        <label class="form-label">Confirm Password </label>
                                        <input type="password" class="form-control" placeholder="" value={forgot_confirm_password} onChange={(e)=>{setForgotConfirmPassword(e.target.value)
                                          setForgotConfirmPasswordErr(false)
                                        } }/>
                                        {forgot_confirm_passwordErr && <p className="field-err">Please enter correct confirm password </p>}
                                    </div>
                                </div>
                                
                                <button type="submit" class="btn btn-solid color1">Submit</button>
                            </form>
                        </div>      
                        <div class="modal-footer">
                            <div class="text-end">
                                <a class="return_login" href="#" onClick={()=>loginmodal()}><i class="fa fa-arrow-left"></i> Back to Login</a>
                            </div>
                        </div> 
                    </div>
                     )}            
            </Modal>
            </>
    )

}

export default Head;
