import React ,{useEffect, useState} from 'react'
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import {useActions} from "../../redux/actions";
import * as authActions from "../../redux/actions/auth";
import {  useSelector  } from "react-redux";
import {service} from '../../services.js' 
import { NotificationManager} from 'react-notifications';


export default function Hotel(props) {

    const {addToFavourite,removeFromFavourite} = useActions(authActions)
    const [fav,setFav] = useState(0)
    const [type,setType] = useState('normal')
   
    const hotel = props.data
   
    const user = useSelector((state)=> state.user)

    const AddToFav = () => {
      
        let payload = {
            property_id:props.data.id,           
            token:user.data.token
        }
        addToFavourite(payload).then(async (res)=>{       
                setFav(1)
        });
    }

    const removeFromfav = () => {
       
        let payload = {
            property_id:props.data.id,           
            token:user.data.token
        }
        removeFromFavourite(payload).then(async (res)=>{       
                setFav(0)
        });
    }


    const getFavDetail = async (url) => {
        if(!user.data){
            return
        }
        let payload = {
            property_id:props.data.id,           
            token:user.data.token
        }
        console.log('payload',payload)
        var d = await service.postAuthRequest(url,payload)  
      
        if(d.status===200){            
            setFav(d.data.data)
            
        }
    }

    useEffect(() => {   
            getFavDetail('favourite/check')
            if(props.type){
                setType(props.type)
            }
    },[]);


    const favourit = (e) => {
        e.preventDefault();
        if(!user.data){
              NotificationManager.error('Error message', "Please login to add properties into Favourite");
            return;
        }
        if(fav==0){
            AddToFav()
        }else{
            removeFromfav()
        }
    }
    

    return (
        <>
      
            <div className={type=='related'?'':"col-xl-6 col-sm-6 popular grid-item wow fadeInUp"} >


                <div className="special-box">
                    <div className="special-img">
                        <Link to={{
                        pathname: `/hotel-detail/${hotel.slug}`,
                       
                      }}>

                        {hotel.featured_image && hotel.featured_image.images ?
                            (<img src={hotel.featured_image.images} className="img-fluid blur-up lazyload bg-img" alt="" />)
                            :
                            (<Image src={ require("../../assets/images/hotel/gallery/1.jpg")} className="img-fluid blur-up lazyload bg-img" alt="" />)
                        }
                        </Link>
                        <div className="top-icon" onClick={(e)=>favourit(e)} >
                            <a href="#" className="" data-bs-toggle="tooltip" data-placement="top" title="" data-original-title="Add to Wishlist">
                           
                               {fav=="1" ?
                                    (<i className="fa fa-heart"></i>)
                                    :
                                    (<i className="far fa-heart"></i>)
                               }
                            </a>
                        </div>
                    </div>
                    <div className="special-content">
                        <Link to={{
                        pathname: `/hotel-detail/${hotel.slug}`,
                       
                      }}>
                       
                            <h5>{hotel.property_name} <span><i className="fas fa-map-marker-alt"></i> {hotel.country_detail.name}</span></h5>
                        
                        </Link>
                     {/*   <p
                             dangerouslySetInnerHTML={{
                                            __html:hotel.description
                                          }}>
                        </p>*/}
                        <div className="bottom-section">
                            <div className="rating">
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                                <i className="far fa-star"></i>
                                <span>0 review</span>
                            </div>
                            <div className="price">
                                 {/*  <del>$0</del>
                             <span>${hotel.price}</span> */}
                                <div className="facility-detail">
                                 
                                   {hotel.facilities.split(',').map(function(object, i){
                                    return ( <span key={i}>{object}</span>)
                                 })}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="label-offer">hot deal</div>
                </div>
            </div>
                            
        </>
    )
}
