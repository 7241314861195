import { LOGIN, LOGOUT } from "../actionTypes";

const initialState = null;

const user = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        ...action.payload
      };
    }
  
    case LOGOUT: {
      return null;
    }
    default:
      return state;
  }
}

export default user;