import React from 'react'
import {Image } from 'react-bootstrap';
export default function HotelWords() {
    return (
        <section className="hotel_words">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12 text-center">
                        <h3 className="new-title"><span>A FEW WORDS</span></h3>
                        <p>Book your room right now and start your amazing adventure full of discoveries and experiences with Idowaz.</p>
                    </div>
                    <div className="col-sm-4">
                        <div className="box">
                            <div className="hotel_icon">
                                <Image src={require('../../assets/images/new/h1.png')} alt="idowaz" />
                            </div>
                            <h3>Luxury</h3>
                            <p>Located in the heart of Panjim City and 3 km away from Miramar Beach, Palacio De Goa offers comfy accommodation enriched with courteous facilities.</p>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="box">
                            <div className="hotel_icon">
                                <Image src={require('../../assets/images/new/h2.png')} alt="idowaz" />
                            </div>
                            <h3>GREAT SERVICES</h3>
                            <p>Hotel customer service is the care provided by hotels to guests before, during, and after their stay.</p>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="box last_box">
                            <div className="hotel_icon">
                                <Image src={require('../../assets/images/new/h3.png')} alt="idowaz" />
                            </div>
                            <h3>ONLINE RESERVATION</h3>
                            <p>Idowaz provides online Hotel Bookings of hotels in India and worldwide. Book cheap, budget and luxury hotels at great prices on best hotel booking site</p>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}
