import { combineReducers } from "redux";
import user from './user'
import alert from './alert'
import cart from './cart'
import coupon from './coupon'
export default combineReducers({
	user,
    alert,
    cart,
    coupon
 
});

