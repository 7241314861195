import React, {useState,useRef, useEffect} from 'react'
import { Image } from 'react-bootstrap';
import { Link ,useParams} from 'react-router-dom'
import {service} from '../services.js'
import moment from 'moment';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-datetime/css/react-datetime.css';
import DatePicker from 'react-datetime';
import Room from './partials/Room'
import RelatedProperties from './partials/RelatedProperties'

import GoogleMapReact from 'google-map-react';

export default function HotelDetail() {
    
    const slider = useRef(null);
    const slider2 = useRef(null);
    let { slug } = useParams();
    const [hotel, setHotel] = useState([]);
    
    const [activeTab, setActiveTab] = useState('rooms');
    const [latlng,setLatLng] = useState({
      lat:  20.5937,
      lng: 78.9629
    })
     
   const AnyReactComponent = ({ text }) => <div style={{position: "absolute",
  top: "50%",
  left: "50%",
  width: "18px",
  height: "18px",
  backgroundColor:"#000",
  border: "2px solid #fff",
  borderRadius: "100%",
  userSelect: "none",
}}
  > </div>;

    const [slideIndex, setSlideIndex] = useState(1);
    const getProperty = async () => {
        var d = await service.getRequest('property_by_slug/'+slug)       
        if(d.status===200){
            var res = d.data.data
            setHotel(res)       
                setLatLng({
              lat: parseFloat(res.latitude),
              lng: parseFloat(res.longitude)
            })
            
        }  
         console.log(hotel,hotel)
         
        

    }
 
    useEffect(() => {   
        document.title = "Idowaz - Hotel Detail"
        getProperty()
        
    },[]);
  

    useEffect(() => {
        console.log('slideIndex',slideIndex)
        slider.current.slickGoTo(slideIndex)
    }, [slideIndex]);

    const today = moment().subtract(1, 'days');
    const disableFutureDt = current => {
      return current.isAfter(today)
    }

    const onSliderChange = (newIndex) => {
    setSlideIndex(newIndex);
  };


    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        slickGoTo:1,
        dontAnimate:true
    };
    var settings2 = {
        slidesToShow: hotel &&hotel.images ? hotel.images.length>3?3:hotel.images.length :2,
        slidesToScroll: 2,
       
        dots: false,
        arrows: false,
        centerMode: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3
                }
            }
        ],
            afterChange: onSliderChange,
    };

   var relatedSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    slickGoTo:1
  };
  


    return (
        <section className="single-section small-section bg-inner">

            <div className="container">
            
                <div className="row">
                    <div className="col-12">
                        <div className="hotel_title_section">
                            <div className="hotel-name">
                                <div className="left-part">
                                    <div className="top">
                                        <h2>{hotel.property_name}</h2>
                                        <div className="rating">
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="fas fa-star"></i>
                                            <i className="far fa-star"></i>
                                        </div>
                                        <div className="share-buttons">
                                            <Link to="#" className="btn btn-solid"><i className="far fa-share-square"></i> share</Link>
                                            {/*<Link to="#" className="btn btn-solid"><i className="far fa-heart"></i> save</Link>*/}
                                        </div>
                                    </div>
                                    <p>{hotel.address}</p>
                                    <div className="facility-detail">
                                    { hotel.facilities  && hotel.facilities.split(',').map(function(object, i){
                                    return ( <span key={i}>{object}</span>)
                                 })}

                                        
                                    </div>
                                </div>
                                <div className="right-part">
                                    <h2 className="price">₦{hotel.min_room_price? hotel.min_room_price.toLocaleString('hi-IN'):0} <span>/ per night</span></h2>
                                   
                                   {/* <Link className="btn btn-rounded btn-sm color1"  to={{
                                        pathname: `/hotel-booking/${hotel.id}`,
                                      
                                    }}>


                                    book this now</Link>*/}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-9 col-lg-8">
                        <div className="image_section ">
                            <div className="row">
                                <div className="col-sm-12">
                                

                                    <div className="list-img arrow-dark zoom-gallery ratio3_2"  >
                                         <Slider {...settings} className="slider-image" ref={slider}>
                                          { hotel.images  && hotel.images.map(function(object, i){

                                            return (<div key={i}><Image src={object.images} style={{width: "100%"}}
                                                    className="img-fluid blur-up lazyload bg-img" alt="" /></div>)
                                          })}
                                            
                                            
                                         </Slider>
                                         <Slider {...settings2} className="slider-thumbnail" ref={slider2}>

                                           { hotel.images  && hotel.images.map(function(object, i){

                                           return ( <div key={i}><span><Image src={object.images}
                                            className="img-fluid blur-up lazyload bg-img" alt="" onClick={() => setSlideIndex(0)}  /></span></div>)
                                        })}

                                            
                                         </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="description-section tab-section">
                            <div className="menu-top">
                                <ul className="nav nav-tabs" id="top-tab" role="tablist">
                                    <li className="nav-item" onClick={()=>setActiveTab('rooms')} ><Link to-bs-toggle="tab" className={activeTab=='rooms'?'nav-link active':'nav-link'}
                                            to="#rooms">rooms</Link></li>

                                    <li className="nav-item" onClick={()=>setActiveTab('detail')}><Link to-bs-toggle="tab" className={activeTab=='detail'?'nav-link active':'nav-link'}
                                            to="#about">details</Link></li>

                                  {/*  <li className="nav-item" onClick={()=>setActiveTab('review')}><Link to-bs-toggle="tab" className={activeTab=='review'?'nav-link active':'nav-link'}
                                            to="#review">review</Link></li>*/}

                                     <li className="nav-item" onClick={()=>setActiveTab('location')}><Link to-bs-toggle="tab" className={activeTab=='location'?'nav-link active':'nav-link'}
                                            to="#location">location</Link></li>

                                   
                                </ul>
                                <div className="description-details tab-content" id="top-tabContent">
                                    <div className={activeTab=='rooms'?'menu-part tab-pane fade show active':'menu-part tab-pane fade'}  id="rooms">
                                        <table className="rooms-box">
<tbody>
                                          { hotel.rooms  && hotel.rooms.map(function(object, i){
                                            
                                            return (<Room data={object} key={i} />)
                                           
                                             })}
                                          </tbody>
                                        </table>
                                    </div>
                                    <div className={activeTab=='detail'?'about menu-part tab-pane fade show active':'menu-part tab-pane fade'} id="about">
                                      
                                       <div
                                          dangerouslySetInnerHTML={{
                                            __html:hotel.description
                                          }}></div>


                                    </div>
                                    <div className="menu-part tab-pane fade facility" id="facility">
                                        <div className="row">
                                            <div className="col-xl-3 col-6">
                                                <h6><Image src={ require("../assets/images/icon/hotel/verified.png")}
                                                        className="img-fluid blur-up lazyload" alt="" /> basic facility</h6>
                                                <ul>
                                                    <li><i className="fas fa-check"></i> Free Wi-Fi</li>
                                                    <li><i className="fas fa-check"></i> Room Service</li>
                                                    <li><i className="fas fa-check"></i> Elevator Lift</li>
                                                    <li><i className="fas fa-check"></i> Laundry Service</li>
                                                    <li><i className="fas fa-check"></i> Power Backup</li>
                                                    <li><i className="fas fa-check"></i> Free Parking</li>
                                                </ul>
                                            </div>
                                            <div className="col-xl-3 col-6">
                                                <h6><Image src={ require("../assets/images/icon/hotel/credit-card.png")}
                                                        className="img-fluid blur-up lazyload" alt="" /> payment mode</h6>
                                                <ul>
                                                    <li><i className="fas fa-check"></i> visa card</li>
                                                    <li><i className="fas fa-check"></i> master card</li>
                                                    <li><i className="fas fa-check"></i> American express</li>
                                                    <li><i className="fas fa-check"></i> debit card</li>
                                                    <li><i className="fas fa-check"></i> cash</li>
                                                    <li><i className="fas fa-check"></i> online banking</li>
                                                </ul>
                                            </div>
                                            <div className="col-xl-3 col-6">
                                                <h6><Image src={ require("../assets/images/icon/hotel/cctv.png")}
                                                        className="img-fluid blur-up lazyload" alt="" /> security</h6>
                                                <ul>
                                                    <li><i className="fas fa-check"></i> Security Guard</li>
                                                    <li><i className="fas fa-check"></i> CCTV</li>
                                                    <li><i className="fas fa-check"></i> emergency exit</li>
                                                    <li><i className="fas fa-check"></i> doctor on call</li>
                                                </ul>
                                            </div>
                                            <div className="col-xl-3 col-6">
                                                <h6><Image src={ require("../assets/images/icon/hotel/tray.png")}
                                                        className="img-fluid blur-up lazyload" alt="" /> food & drinks</h6>
                                                <ul>
                                                    <li><i className="fas fa-check"></i> restaurant</li>
                                                    <li><i className="fas fa-check"></i> bar</li>
                                                </ul>
                                                <h6 className="mt-2"><Image src={ require("../assets/images/icon/hotel/barbell.png")}
                                                        className="img-fluid blur-up lazyload" alt="" /> activities</h6>
                                                <ul>
                                                    <li><i className="fas fa-check"></i> gym</li>
                                                    <li><i className="fas fa-check"></i> game zone</li>
                                                    <li><i className="fas fa-check"></i> swimming pool</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={activeTab=='location'?'map menu-part tab-pane fade show active':'menu-part tab-pane fade'} id="location">
                                     
                                    {hotel.latitude && (
                                      <div style={{ height: '300px', width: '100%' }}>
                                    
                                      <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyDc-cNs0EnMKNz7wlsQciciZO5PDgDqPcU" }}
                                        defaultCenter={latlng}
     yesIWantToUseGoogleMapApiInternals
                                        defaultZoom={2}
                                      >
                                       <AnyReactComponent
                                          lat={hotel.latitude}
                                          lng={hotel.longitude}
                                          text="My Marker"
                                        />
                                       
                                      </GoogleMapReact>
                                    </div>
)}
                                    </div>

                                    <div className={activeTab=='review'?'map menu-part tab-pane fade show active':'menu-part tab-pane fade'} id="review">
                                        <div className="review-box">
                                            <div className="rating">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <span>The stay in the hotel was excellent</span>
                                            </div>
                                            <h6>by xyz, jun 18, 2021</h6>
                                            <p>Our stay at sea view was pleasant. We stayed here for a day and the view from
                                                the room was brilliant. Rooms were clean hygienic and big. foods were
                                                amazing. rooms were neat and clean.staff is very courteous and
                                                cooperative.great place to stay. Good atmosphere, Staff was amazing..well
                                                education..mannered. Room was spacious and cleaned more then an expected.
                                            </p>
                                        </div>
                                        <div className="review-box">
                                            <div className="rating">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="far fa-star"></i>
                                                <span>Awesome Stay..value for money</span>
                                            </div>
                                            <h6>by xyz, jun 18, 2021</h6>
                                            <p>We were there for 3 nights and hotel was too good. Greenery was flaunting
                                                everywhere. There were games kept for our entertainment. View from room was
                                                good. Hotel staff behavior was nice. They provided each and every
                                                service in hand. Overall stay was too good.</p>
                                        </div>
                                        <div className="review-box">
                                            <div className="rating">
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="fas fa-star"></i>
                                                <i className="far fa-star"></i>
                                                <span>Best getaway destination with family</span>
                                            </div>
                                            <h6>by xyz, jun 18, 2021</h6>
                                            <p>The location, view from the rooms are just awesome. Very cool landscaping has
                                                been done Around the hotel. There are small activities that you can indulge
                                                with your family. Pool wasn't functional at the time of our
                                                stay. In all, the stay is really peaceful, calm and Silent. A place we would
                                                definitely want to visit again.</p>
                                        </div>
                                    </div>
                                    <div className="menu-part tab-pane fade policy" id="policy">
                                        <p>Check-in: 2.00 PM, Check-out: 12.00 PM</p>
                                        <p>The primary guest must be at least 18 years of age to check into this hotel.</p>
                                        <p>It is mandatory for guests to present valid photo identification at the time of
                                            check-in. According to government regulations, a valid Photo ID has to be
                                            carried by every person above the age of 18 staying at the hotel.
                                            The identification proofs accepted are Aadhar Card, Driving License, Voter ID
                                            Card, and Passport. Without Original copy of valid ID the guest will not be
                                            allowed to check-in.
                                        </p>
                                        <p>Local ID proof & Pan card will not be acceptable as ID proof.</p>
                                        <p>Unless mentioned, the tariff does not include charges for optional room services
                                            (such as telephone calls, room service, mini bar, snacks, laundry extra bed
                                            etc.). In case, such additional charges are levied by the
                                            hotel(s), we shall not be held responsible for it.</p>
                                        <p>Personal food and beverages are strictly not permitted on the hotel premises.</p>
                                        <p>The hotel shall not be responsible for any loss of or damage to your personal
                                            belongings.In case any damage is done to the hotel property by guests during
                                            their stay, it will be the sole accountability of the guest.</p>
                                        <p>No charge for children below 6 years and the extra cost will be applicable for
                                            availing an extra bed in a double occupancy room. </p>
                                        <p>Should any action by a guest be deemed inappropriate by the hotel, or if any
                                            inappropriate behaviour is brought to the attention of the hotel, the hotel
                                            reserves the right, after the allegations have been investigated,
                                            to take action against the guest.</p>
                                        <p>We would love to host you but in case your plans change, our simple cancellation
                                            process makes sure you receive a quick confirmation and fast refunds. Our
                                            standard check-in time is 12 noon and you can check-in any time
                                            after that till your reservation is valid.</p>
                                        <p>Pets are not allowed in the hotel premises.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="special-section related-box ratio3_2 grid-box">
                        <h3>Related</h3>
                            <div className="hotel-detail no-arrow">
                                <RelatedProperties id={hotel.id} />
                             
                            </div>
                        </div>
                    </div>


                    <div className="col-xl-3 col-lg-4" id="booking">
                        <div className="">
                        
                            <div className="single-sidebar">
                                <h6 className="contact-title">contact info</h6>
                                <p className="address"> <i className="fas fa-map-marker-alt"></i> {hotel.address}
                                </p>
                                <p><i className="fas fa-phone-alt"></i> {hotel.contact_person_phone_number}</p>
                                
                                {hotel.contact_person_email && (
                                <Link to="#">
                                    <p><i className="fas fa-envelope"></i> {hotel.contact_person_email}</p>
                                </Link>
                                )}
                               
                                <div className="bottom_sec">

                               
                                    <h6>check-in : { moment(hotel.check_in_time,"h:m:i").format("hh:mm A")}</h6>
                                    <h6>check-out :  { moment(hotel.check_out_time,"h:m:i").format("hh:mm A")}</h6>
                                </div>
                            </div>
                           
                        </div>
                    </div>





                </div>
            </div>
        </section>
    )
}
