import React,{useEffect,useState} from 'react'
import {Image } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import {service} from '../../services.js' 


export default function BestValue({currenttab}) {

    const [hotels, setHotel] = useState([]);
    console.log(currenttab,'currenttab')
    const getProperties = async (url) => {
        var type="";
       if(currenttab==1){
            type="hotel";
       }else if(currenttab==2){
        type="apartment";
       }else if(currenttab==3){
        type="house";
       }

        var countryData = await service.getExternalRequest('https://ipapi.co/json/')  
        console.log(countryData,'countryData')
        var country = countryData.data.country_name
        var d = await service.getRequest(url+'?country='+country+'&property_type='+type)  
       
        if(d.status===200){
            setHotel(d.data.data)           
        }
    }

    useEffect(() => {   
            getProperties('best_value_properties')
    },[currenttab]);


    return (
        <section className="best-value">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h3 className="new-title text-center"><span>Best Value</span></h3>
                </div>

                {hotels.map(function(hotel, i){
                                
                            
                    return (<div className="col-lg-4 col-md-6 col-sm-6" key={i}>
                        <div className="room-item">
                            <div className="room-image">
                                 <img src={hotel.featured_image?.images} className="img-fluid" alt="" width="400" />
                            </div>
                            <div className="room-content">
                                <div className="room-title">
                                    <h4>{hotel.name}</h4>
                                  {/*   <p className="price-ft"><i className="fa fa-tags"></i> ${hotel.price} <span>/ per Night</span></p> */}
                                      {/* <div className="deal-rating">
                                        <span className="fa fa-star checked"></span>
                                        <span className="fa fa-star checked"></span>
                                        <span className="fa fa-star checked"></span>
                                        <span className="fa fa-star checked"></span>
                                        <span className="fa fa-star checked"></span>
                                    </div> */}
                                </div>
                                <p
                                          dangerouslySetInnerHTML={{
                                            __html:hotel.description
                                          }}></p>


                              
                                <div className="room-btns">
                                   <Link className="btn btn-solid color1" to={{
                                        pathname: `/hotel-detail/${hotel.slug}`                                       
                                      }}>

                                    View Details</Link>
                                {/*    <Link className="btn btn-solid color1" to={{
                                        pathname: `/hotel-booking/${hotel.id}`                                       
                                      }}>Book Now</Link>
                                  */}
                                </div>
                            </div>
                        </div> </div>)
               
                
                })}



            </div>
        </div>
    </section>
    )
}

