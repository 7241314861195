import React,{useState,useEffect} from 'react'
import { Modal} from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom'
import {  useSelector  } from "react-redux";
import { useNavigate } from "react-router-dom";
import {useActions} from "../../redux/actions";
import * as authActions from "../../redux/actions/auth";
import moment from 'moment';
import { NotificationManager} from 'react-notifications';

import 'react-datetime/css/react-datetime.css';
import DatePicker from 'react-datetime';
import {service} from '../../services.js'
import ImageUploading from 'react-images-uploading';

export default function Bookings() {

    const [show, setShow] = useState(false);
    const [showview, setShowView] = useState(false);
    const [reason, setReason] = useState('');
    const[booking_id,setBookingId] = useState(null);
     const[bookingDetail,setBookingDetail] = useState(null);

    const user = useSelector((state)=> state.user)
    const {profileAction} = useActions(authActions)
    const navigate = useNavigate();
    const [bookings, setBookings] = useState([]);
    
    useEffect(async () => {  
        
       
        getBookings();

    },[]);

    const getBookings =async () =>{
        if(!user || !user.data){           
            navigate("/");   
        }
         let payload = {
            token:user.data.token
        }

         var d = await service.getRequest('bookings',payload)       
        if(d.status===200){              
           setBookings(d.data.data)
        }
    }

    const openModel=(id)=>{
        setShow(true)
        setReason('')
        setBookingId(id)
    }

    const openModelView=(detail)=>{
        setShowView(true)
        setBookingDetail(detail)
    }
    const handleSubmit =async (e)=> {

        e.preventDefault();
        if(!user.data){
            return
        }
        let payload = {
            booking_id:booking_id,           
            token:user.data.token,
            reason:reason
        }
        
        var d = await service.postAuthRequest('cancel-booking',payload)  
        if(d.status===200){            
            
            NotificationManager.success('Success message', d.data.message);
            setShow(false)
            var list = bookings
            list = list.map((e)=>{
                if(e.id==booking_id){
                    e.status ='Cancel';
                }
                return e
            })
           setBookings(list)
           getBookings()
        }else{
             NotificationManager.error('Error message', d.data.message);
        }

    }




    return (
        <>
            <div className="dashboard-box">
                                                <div className="dashboard-title">
                                                    <h4>bookings</h4>
                                                </div>
                                              {bookings.map(function(booking, i){
                                               return  (<div className="dashboard-detail">
                                                    <div className="booking-box">
                                                       {/* <div className="date-box">
                                                            <span className="day">tue</span>
                                                            <span className="date">14</span>
                                                            <span className="month">feb</span>
                                                        </div>*/}
                                                        <div className="detail-middle">
                                                            <div className="media">
                                                                <div className="icon">
                                                                    <i className="fas fa-hotel"></i>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h6 className="media-heading">{booking.property.property_name}</h6>
                                                                    <p>amount : <span>₦{booking.amount}</span></p>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h6 className="media-heading">ID: {booking.id}</h6>
                                                                    <p>order date: <span>{moment(booking.created_at).format("MM/DD/YYYY")}</span></p>
 </div>
                                                                    
                                                            </div>
                                                        </div>
                                                          
                                                        <div className="detail-last">

                                                        {booking.status=='cancel'?'':
                                                            (<Link to="#" onClick={(e)=>openModel(booking.id)} ><i className="fas fa-window-close" data-bs-toggle="tooltip"
                                                                    data-placement="top" title="cancle booking"></i></Link>)}
                                                            <span className="badge bg-info">{booking.status}</span>
                                                            <span className="badge bg-info" onClick={(e)=>openModelView(booking)}>View</span>
                                                         <Link className="btn btn-solid color" to={{
                                        pathname: `/hotel-detail/${booking.property.slug}`                                       
                                      }}>

                                   ReBook</Link>
                                                        </div>
                                                    </div>
                                                </div>)
                                              })}

                                              {bookings.length==0 ? (<div className="alert alert-warning">No Booking found</div> ) : null}
                                               
                                            </div>
                                           


            <Modal show={show} onHide={()=>setShow(false)} className="booking-cancel">
                <div className="modal-dialogS">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Booking Cancel</h5>
                            <button type="button" className="btn-close"onClick={(e)=>setShow(false)} aria-label="Close"></button>
                        </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <h5>Are you sure you want to cancel this booking?</h5>
                            
                                <label>Cancel Notes</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" value={reason} onChange={(e)=>setReason(e.target.value)} ></textarea>
                           
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-solid" data-bs-dismiss="modal">Yes, Cancel Booking</button>
                            <button type="button" className="btn btn-secondary" onClick={(e)=>setShow(false)}>No, Don't Cancel</button>
                        </div>
                     </form>
                    </div>
                </div>             
            </Modal>

            <Modal show={showview} onHide={()=>setShowView(false)} className="booking-cancel">
                <div className="modal-dialogS">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Booking Detail</h5>
                            <button type="button" className="btn-close"onClick={(e)=>setShowView(false)} aria-label="Close"></button>
                        </div>
                   
                        <div className="modal-body">
                         <div className="detail-last1">

                         </div>
                            
                          <div className="detail-last1">
                                <h5>Rooms Information</h5>
                                {bookingDetail && bookingDetail.rooms.map(function(room, i){
                                    return(<div class="room" style={{background: "eee",
                                    marginBottom: "5px",
                                    padding: "10px"}}>
                                    <div class="room-title">Room: {room.room.title}</div>
                                    <div class="room-checkIn">checkIn: {moment(room.check_in).format("MM/DD/YYYY h:mm A")}</div>
                                    <div class="room-checkIn">checkOut: {moment(room.check_out).format("MM/DD/YYYY h:mm A")}</div>
                                    <div class="room-rooms">Rooms: {room.no_of_rooms}</div>
                                    <div class="room-rooms" style={{ textTransform: "capitalize"}}>Status: {bookingDetail.status}</div>

                                    </div>)
                                })}
                                <h5>Payment Status</h5>
                                <div class="room" style={{background: "eee",
                                    marginBottom: "5px",
                                    padding: "10px"}}>
                                   <div class="room-title"> Amount: ₦{bookingDetail && (bookingDetail.amount)}</div>
                                   <div class="room-title" style={{ textTransform: "capitalize"}}> Payment Status: {bookingDetail && (bookingDetail.payment_status)}</div>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={(e)=>setShowView(false)}>Close</button>
                        </div>
                     
                    </div>
                </div>             
            </Modal>


        </>
    )
}
