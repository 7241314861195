import React ,{useState,useEffect} from 'react'
import { Image } from 'react-bootstrap';
//import { Link, useLocation } from 'react-router-dom'
import Profile from './partials/Profile'
import LoginDetails from './partials/LoginDetails'
import {  useSelector  } from "react-redux";
import { useNavigate } from "react-router-dom";

import FavoriteProperties from './partials/FavoriteProperties'
import Bookings from './partials/Bookings'
function Dashboard() {

    const [currenttab, setCurrentTab ] = useState("1")
    const navigate = useNavigate();
    const user = useSelector((state)=> state.user)
    const userDetail = user && user.data?user.data.data:{};


    useEffect(() => {  
        if(!user || !user.data){           
            navigate("/");  
        }
         document.title = "Idowaz - Dashboard"

    });

    
    return (
        <>
            <section className="breadcrumb-section pt-0 bg-size blur-up lazyloaded">
                    <div className="breadcrumb-content">
                        <div>
                            <h2>dashboard</h2>
                            <nav aria-label="breadcrumb" className="theme-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">dashboard</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section>

                <section className="small-section dashboard-section bg-inner" data-sticky_parent>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="pro_sticky_info" data-sticky_column>
                                    <div className="dashboard-sidebar">
                                        <div className="profile-top">
                                            <div className="profile-image">
                                         
                                           
                                            {userDetail.profile_pic!='' ?
                                                (<img src={userDetail.profile_pic} className="img-fluid blur-ups lazyload" alt="" /> )                                               
                                            :
                                                (<Image src={ require("../assets/images/avtar/1.png")} className="img-fluid blur-up lazyload" alt="" /> )                                               
                                            }
                                            </div>
                                            <div className="profile-detail">
                                                <h5>{userDetail.first_name} {userDetail.last_name}</h5>
                                                <h6>{userDetail.phone_number}</h6>
                                                <h6>{userDetail.email}</h6>
                                            </div>
                                        </div>
                                        <div className="faq-tab">
                                            <ul className="nav nav-tabs" id="top-tab" role="tablist">
                                                <li className="nav-item" onClick={()=>setCurrentTab("1")}><a data-bs-toggle="tab" className={"nav-link "+ (currenttab==="1"?"active":"")} href="javascript:void(0)">profile</a></li>
                                                <li className="nav-item" onClick={()=>setCurrentTab("2")}><a data-bs-toggle="tab" className={"nav-link "+ (currenttab==="2"?"active":"")} href="javascript:void(0)">My bookings</a></li>
                                                <li className="nav-item" onClick={()=>setCurrentTab("3")}><a data-bs-toggle="tab" className={"nav-link "+ (currenttab==="3"?"active":"")} href="javascript:void(0)">My Favorite</a></li>
                                                <li className="nav-item"><a target="_blank" className="nav-link" href='https://idowaz.com/certified-label-program'>Certified Label Program</a></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9" id="leftside">
                              
                                <div className="product_img_scroll" data-sticky_column>
                                    <div className="faq-content tab-content" id="top-tabContent">
                                        <div className="tab-pane fade " id="dashboard">
                                            <div className="dashboard-main">
                                                <div className="dashboard-intro">
                                                    <h5>welcome! <span>Mark Enderess</span></h5>
                                                    <p>you have completed 70% of your profile. add basic info to complete profile.
                                                    </p>
                                                    <div className="complete-profile">
                                                        <div className="row">
                                                            <div className="col-xl-4">
                                                                <div className="complete-box">
                                                                    <i className="far fa-check-square"></i>
                                                                    <h6>verified email ID</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4">
                                                                <div className="complete-box">
                                                                    <i className="far fa-check-square"></i>
                                                                    <h6>verified phone number</h6>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-4">
                                                                <div className="complete-box not-complete">
                                                                    <i className="far fa-window-close"></i>
                                                                    <h6>complete basic info</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="counter-section">
                                                    <div className="row">
                                                        <div className="col-xl-3 col-sm-6">
                                                            <div className="counter-box">
                                                                <Image src={ require("../assets/images/icon/hotel.png")}
                                                                    className="img-fluid blur-up lazyload" alt="" />
                                                                <h3>25</h3>
                                                                <h5>hotel booked</h5>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div className="dashboard-info">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div id="chart">
                                                                <div className="detail-left">
                                                                    <ul>
                                                                        <li><span className="completed"></span> Completed</li>
                                                                        <li><span className="upcoming"></span> Upcoming</li>
                                                                        <li><span className="cancelled"></span> Cancelled</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {currenttab==="1" && (
                                        <div className="tab-pane fade show active" id="profile">
                                            <div className="dashboard-box">
                                                <Profile />
                                            </div>
                                            <div className="dashboard-box">
                                               <LoginDetails />                                                   
                                            </div>
                                        </div>
                                        )}
                                        
                                        {currenttab==="2" && (
                                        <div className="tab-pane fade show active" id="bookings">
                                            <Bookings />
                                        </div>
                                        )}
                                        
                                        {currenttab==="3" && (
                                            <FavoriteProperties />

                                        )}
                                        <div className="tab-pane fade" id="security">
                                            <div className="dashboard-box">
                                                <div className="dashboard-title">
                                                    <h4>delete your accont</h4>
                                                </div>
                                                <div className="dashboard-detail">
                                                    <div className="delete-section">
                                                        <p>Hi <span className="text-bold">Mark Enderess</span>,</p>
                                                        <p>we are sorry to here you would like to delete your account.</p>
                                                        <p><span className="text-bold">note:</span></p>
                                                        <p>deleting your account will permanently remove your profile, personal
                                                            settings, and all other associated information.
                                                            once your account is deleted, you will be logged out and will be unable
                                                            to log back in.
                                                        </p>
                                                        <p>if you understand and agree to the above statement, and would still like
                                                            to delete your account, than click below</p>
                                                        <a href="#" data-bs-toggle="modal" data-bs-target="#delete-account"
                                                            className="btn btn-solid">delete my account</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </section>


                



                <div className="modal fade modal-btn" id="re-book" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Re-Booking</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                        <div className="modal-body">
                            <div className="single-section ">
                                    <div className="single-sidebar">
                                        
                                            <div className="book-btn-section">
                                                <input placeholder="Check In" id="datepicker2" />
                                                <input placeholder="Check Out" id="datepicker1" />
                                                <div className="rooms-section">
                                                    <input type="text" className="form-control open-select"
                                                        placeholder="rooms & guests" />
                                                    <div className="selector-box">
                                                        <div className="room-cls">
                                                            <label className="title_room">room 1</label>
                                                            <div className="qty-box">
                                                                <label>adult</label>
                                                                <div className="input-group">
                                                                    <button type="button" className="btn quantity-left-minus"
                                                                        data-type="minus" data-field=""> - </button>
                                                                    <input type="text" name="quantity"
                                                                        className="form-control qty-input input-number" value="1" />
                                                                    <button type="button" className="btn quantity-right-plus"
                                                                        data-type="plus" data-field="">+</button>
                                                                </div>
                                                            </div>
                                                            <div className="qty-box">
                                                                <label>children</label>
                                                                <div className="input-group">
                                                                    <button type="button" className="btn quantity-left-minus"
                                                                        data-type="minus" data-field=""> - </button>
                                                                    <input type="text" name="quantity"
                                                                        className="form-control qty-input input-number" value="1" />
                                                                    <button type="button" className="btn quantity-right-plus"
                                                                        data-type="plus" data-field=""> + </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="bottom-part">
                                                            <a href="#" className="add-room">add room +</a>
                                                            <a href="#!" className="btn">apply</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="selector">
                                                    <select>
                                                        <option value="" disabled >Room Type</option>
                                                        <option value="saab">Deluxe Room</option>
                                                        <option value="audi">Suite</option>
                                                        <option value="opel">Royal room</option>
                                                    </select>
                                                </div>
                                                <a href="#" className="btn btn-rounded btn-sm color1">book this now</a>
                                            </div>
                                        

                                    </div>
                            </div>
                        
                        </div>
                    
                    </div>
                </div>
                </div>
        </>
    )
}

export default Dashboard
