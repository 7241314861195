import React ,{useEffect, useState} from 'react'
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import {useActions} from "../../redux/actions";
import * as authActions from "../../redux/actions/auth";
import {  useSelector  } from "react-redux";
import {service} from '../../services.js' 
import { NotificationManager} from 'react-notifications';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css'



export default function Filters(props) {

	const [featurs,setFeatures]  = useState([]);
	
    
    const STEP = 10;
    const MIN = 0;
    const MAX = 10000;
   
    const {setPrice,price_value,saveFeatures,selectedFeature} = props;

	useEffect(() => {   
            GetFeatures()
    },[]);

    const GetFeatures= async() => {
       
        var d = await service.getRequest('getFeatures')  
      
        if(d.status===200){                        
            setFeatures(d.data.data)
        }
    }

    


    const handle = (e) => {       
        
        setPrice(e)
    } 

    const onChange = (event) =>{
        //console.log(event.target.checked);
        const isChecked = event.target.checked;
        const value = event.target.value;

        

        //update list
       
        saveFeatures(isChecked,value)           
        
    }

    const loadHotels =() => {
       
        //getProperties('properties?property_type=hotel&features='+selectedFeature.join(',')+"&minPrice="+value.min+'&maxPrice='+value.max)
    }
	return (
		 <div className="collection-collapse-block-content">
                                    
            <div className="filter-block">
                <div className="collection-collapse-block open">
                    <h6 className="collapse-block-title">price range</h6>
                    <div className="collection-collapse-block-content">
                        <div className="wrapper">
                            
                            <InputRange
                                maxValue={MAX}
                                minValue={MIN}
                                value={price_value}
                                onChange={e => handle(e)} />
    

                        </div>
                    </div>
                </div>
            </div>
            <div className="filter-block">
                <div className="collection-collapse-block open">
                    <h6 className="collapse-block-title">facility</h6>
                    <div className="collection-collapse-block-content">
                        <div className="collection-brand-filter">
                             {featurs.map(function(object, i){
                                return ( <div className="form-check collection-filter-checkbox" key={i}>
                                <input type="checkbox" className="form-check-input" id={'featur_'+object.id} value={object.id}    onChange={(e)=>onChange(e)}   />
                                <label className="form-check-label" htmlFor={'featur_'+object.id}>{object.title}</label>
                            </div>)
                            })}                          
                            
                        </div>
                    </div>
                </div>
            </div>

            
{/*
            <div className="filter-block">
                <div className="collection-collapse-block open">
                    <h6 className="collapse-block-title">star category</h6>
                    <div className="collection-collapse-block-content">
                        <div className="collection-brand-filter">
                            <div className="form-check collection-filter-checkbox">
                                <input type="checkbox" className="form-check-input" id="five" />
                                <label className="form-check-label rating" htmlFor="five">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <span className="ms-1">(0)</span>
                                </label>
                            </div>
                            <div className="form-check collection-filter-checkbox">
                                <input type="checkbox" className="form-check-input" id="four" />
                                <label className="form-check-label rating" htmlFor="four">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="far fa-star"></i>
                                    <span className="ms-1">(0)</span>
                                </label>
                            </div>
                            <div className="form-check collection-filter-checkbox">
                                <input type="checkbox" className="form-check-input" id="three" />
                                <label className="form-check-label rating" htmlFor="three">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="far fa-star"></i>
                                    <i className="far fa-star"></i>
                                    <span className="ms-1">(0)</span>
                                </label>
                            </div>
                            <div className="form-check collection-filter-checkbox">
                                <input type="checkbox" className="form-check-input" id="two" />
                                <label className="form-check-label rating" htmlFor="two">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="far fa-star"></i>
                                    <i className="far fa-star"></i>
                                    <i className="far fa-star"></i>
                                    <span className="ms-1">(0)</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        */}
        </div>
		)

}