import React ,{useState,useEffect} from 'react'
import { Image } from 'react-bootstrap';
import { Link , useParams} from 'react-router-dom'
import {service} from '../services.js'
import BookingForm from './partials/BookingForm'
import BookingSummary from './partials/BookingSummary'
import moment from 'moment';
import {  useSelector  } from "react-redux";
export default function HotelBooking() {

    const cart = useSelector((state)=> state.cart)
  
    useEffect(() => {  
            
            document.title = "Idowaz - Hotel Booking"
    },[]);
     

    

    

    return (
       <>
       <section class="breadcrumb-section pt-0">
           <div class="breadcrumb-content">
                <div>
                    <h2>Booking</h2>
                    <nav aria-label="breadcrumb" class="theme-breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item">hotels</li>
                            <li class="breadcrumb-item active">Booking</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </section>


            <section class="section-b-space bg-inner animated-section">
                <div class="animation-section">
                    <div class="cross po-2"></div>
                    <div class="round po-5"></div>
                    <div class="round r-2 po-6"></div>
                    <div class="round r-2 po-7"></div>
                    <div class="round r-y po-8"></div>
                    <div class="square po-10"></div>
                    <div class="square s-2 po-12"></div>
                </div>
                <div class="container">
                    <div class="row">

                    {cart.length >0 ? (
                        <>
                        <BookingForm />

                        <BookingSummary />
                        </>
                        ):
                    (
                  
                    <div className="text-center col">

                           <Image src={require("../assets/images/no-cart.png")} className="img-fluid blur-up lazyload" alt="" />
                           <h2>Empty Cart!</h2>
                    </div>
                    )
                }
                    </div>
                </div>
            </section>
       </>
    )
}
