import React ,{useEffect} from 'react'

import { Link ,useParams} from 'react-router-dom'
import { Image } from 'react-bootstrap';
//import { Link, useLocation } from 'react-router-dom'
export default function HotelBookingSuccess() {
    let { id } = useParams();

    useEffect(() => {
      localStorage.removeItem('cart');
      localStorage.removeItem('coupon');
      
    }, []);

    return (
        <section className="bg-inner section-b-space success-section">
            <div className="container">
                <div className="row success-detail mt-0">
                    <div className="col">
                        <Image src={require("../assets/images/hotel/booking-success.png")} className="img-fluid blur-up lazyload" alt="" />
                        <h2>Payment Successful ! get ready for comfortable stay.</h2>
                        <p>thank you for you payment. we have received your payment successfully.  You will get an email invoice soon!</p>
                      
                    </div>
                </div>
            </div>
        </section>
    
    )
}
