import React ,{useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'
export default function PageNotFound() {

    useEffect(() => {
       document.title = "404 - Page not found"
    }, []);

    return (
         <section className="bg-inner section-b-space success-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                    <div class="flex-center position-ref full-height welcome-pg">
                        <div class="content">
                            <div class="m-b-md welcome-msg text-center">
                                <img src="https://idowaz.com/custm-img/404.jpg" alt="Idowaz 404 Error" />
                                <h2 class="welcome" style={{    fontSize: "20px",
    paddingBottom: "15px"}}>We are sorry, But The page you requested was not found</h2>
                                <div class="btn-div">
                                <Link className="btn btn-solid color1" to="/">Home</Link>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </section>
    )
}