import { useState, useEffect } from "react";
//imported css
import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/css/style.css'
import './assets/css/font-awesome.css'
import './assets/css/animate.css'
import './assets/css/themify-icons.css'
import './assets/css/color1.css'
import './assets/css/slick.css'
import './assets/css/slick-theme.css'
//routing
import {
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";


//components
import Home from  "./components/Home";
import Signup from "./components/Signup";
import PartnerSignup from "./components/PartnerSignup";
import Terms from "./components/Terms";
import Hotels from "./components/Hotels";
import HotelDetail from "./components/HotelDetail"
import HotelBooking from './components/HotelBooking'
import HotelCheckout from './components/HotelCheckout'
import HotelBookingSuccess from './components/HotelBookingSuccess'
import HotelBookingFailure from './components/HotelBookingFailure'
import Dashboard from './components/Dashboard'
import PageNotFound from './components/PageNotFound'
import AlertContainer from './components/AlertContainer'
import Loader from "./Loader";


//import NotFoundPage from './NotFoundPage';
//import Navbars from './common/navbar';
import Head from './common/Head';
import Footer from './common/Footer';

//redux
import { Provider } from "react-redux";
import {store} from './store'

store.subscribe(()=> {
  //console.log(store.getState())
  });



const Routeing = () => {
  let routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/home", element: <Home /> },
    { path: "/signup", element: <Signup /> },
    {path:"/partner-signup",element:<PartnerSignup />},
	{ path: "/terms-conditions", element: <Terms /> },
	{ path: "/hotels", element: <Hotels /> },
	{ path: "/hotel-detail/:slug", element: <HotelDetail /> },
	{ path: "/hotel-booking", element: <HotelBooking /> },
	{ path: "/hotel-checkout", element: <HotelCheckout /> },
	{ path: "/booking-success/:id", element: <HotelBookingSuccess /> },
	{ path: "/booking-failed/:id", element: <HotelBookingFailure /> },
	{ path: "/dashboard", element: <Dashboard /> },
    { path: "*", element: <PageNotFound /> },
   
  ]);
  return routes;
};

/*function App() {
  return (   
  <Provider store={store}>  
      <Router>
          <Routeing />       
      </Router>
      </Provider>
  );
}*/



function App() {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    let timer = setTimeout(() => setLoaded(true), 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (   
    <Provider  store={store}>
      	
   
			<Router path='/'>
				<Head /> 
        <Loader />
				<Routeing />
           <AlertContainer/>  
				<Footer />
			</Router>
	
    </Provider>
  );
}


export default App;
