//import { Image } from 'react-bootstrap';
import { useState ,useEffect} from "react";

import { Link } from 'react-router-dom'


import 'react-datetime/css/react-datetime.css';

/*import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'*/

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {service} from '../services.js'
//import ImageUploading from 'react-images-uploading';
import * as authActions from "../redux/actions/auth";
import {useActions} from "../redux/actions";

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { useNavigate } from "react-router-dom";

function Signup() {
        
    
    const [user_type,setUserType ]= useState('partner');
    const [first_name,setFirstName ]= useState('');
    const [last_name,setLastName ]= useState('');
    const [email,setEmail ]= useState('');
    const [otp,setOtp ]= useState('');
    const [phone_number,setPhoneNumber ]= useState('');
    const [phone_code,setPhoneCode]= useState('');
    const [password,setPassword ]= useState('');
    const [confirm_password,setConfirmPassword ]= useState('');

    
    const [country,setCountry ]= useState('');
    const [state,setState ]= useState('');
    const [city,setCity ]= useState('');
    const [zip,setZip ]= useState('');


    const [front_id,setFrontId ]= useState('');
    const [back_id,setBackId ]= useState('');
    const [second_front_id,setSecondFrontId ]= useState('');
    const [second_back_id,setSecondBackId ]= useState('');

    //error
    const [first_nameErr,setFirstNameErr ]= useState(false);
    const [last_nameErr,setLastNameErr ]= useState(false);
    const [emailErr,setEmailErr ]= useState(false);
    const [otpErr,setOtpErr ]= useState(false);
    const [phone_numberErr,setPhoneNumberErr ]= useState(false);
    const [passwordErr,setPasswordErr ]= useState(false);
    const [confirm_passwordErr,setConfirmPasswordErr ]= useState(false);
    
    const [state_err,setStateErr ]= useState(false);
    const [city_err,setCityErr ]= useState(false);
    const [zip_err,setZipErr ]= useState(false);
    const [country_code,setCountyCode] = useState(false);

    const [front_id_err,setFrontIdErr ]= useState(false);
    const [back_id_err,setBackIdErr ]= useState(false);
    const [second_front_id_err,setSecondFrontIdErr ]= useState(false);
    const [second_back_id_err,setSecondBackIdErr ]= useState(false);

    const [termsErr,setTermsErr] = useState(false);
    const [tokenErr, setTokenErr] = useState(false);

    const {register,otpAction} = useActions(authActions)
    const [images, setImages] = useState([]);
    const [terms,setTerms] = useState('');

    const [states,setStates] = useState([])
    const [cities,setCities] = useState([])

    //const maxNumber = 4;
    const [token, setToken] = useState();
    const navigate = useNavigate();
    const handleSubmit= (e) =>{
        e.preventDefault()
    }


    const handleSubmitForm = (e) =>{
        e.preventDefault()
        setFirstNameErr(false)
        setLastNameErr(false)
        setEmailErr(false)
        setPhoneNumberErr(false)
        setOtpErr(false)
        setPasswordErr(false)
        setConfirmPasswordErr(false)
        setFrontIdErr(false)
        setBackIdErr(false)
        setSecondFrontIdErr(false)
        setSecondBackIdErr(false)
        setStateErr(false)
        setCityErr(false)
        setZipErr(false)

        var err = false
        if(!first_name){
          setFirstNameErr(true)
          err = true
        }
        if(!last_name){
          setLastNameErr(true)
          err = true
        }
        if(!email){
          setEmailErr(true)
          err = true
        }
        if(!phone_number){   
              
          setPhoneNumberErr(true)
          err = true
        }
        if(!otp){
          setOtpErr(true)
          err = true
        }
        if(!password){
          setPasswordErr(true)
          err = true
        }
        if(!confirm_password){
          setConfirmPasswordErr(true)
          err = true
        }

        if(password!==confirm_password){
          setConfirmPasswordErr(true)
          err = true
        }

        if(!state){
          setStateErr(true)
          err = true
        }

        if(!city){
          setCityErr(true)
          err = true
        }

        if(!zip){
          setZipErr(true)
          err = true
        }

        if(!terms){
          setTermsErr(true)
          err = true
        }
        if(!token){
          setTokenErr(true)
          err = true
        }

        
        if(!front_id){
        setFrontIdErr(true)
        }
        if(!back_id){
        setBackIdErr(true)
        }
        if(!second_front_id){
        setSecondFrontIdErr(true)
        }
        if(!second_back_id_err){
        setSecondBackIdErr(true)
        }
        

        if(err){
          return false
        }
        let payload ={
            first_name:first_name,
            last_name:last_name,
            email:email,
            phone_number:phone_number.slice(phone_code.length),
            otp:otp,
            images:images,
            password:password,
            confirm_password:confirm_password,
            user_type:user_type,
            front_id:front_id,
            back_id:back_id,
            second_back_id:second_back_id,
            second_front_id:second_front_id,
            state:state,
            city:city,
            zipcode:zip,
            phone_code:phone_code,
            country_code:country_code
        }
        register(payload).then(async (res)=>{   
             if(res ===true){    
              
               navigate("/");   
            }  
        });

    }

    useEffect(async () => {  
        var d = await service.getRequest('getCities/'+state)       
        if(d.status===200){           
          setCities(d.data.data)
        }

    },[state]);


    useEffect(async () => {  
      getStates(91)

    },[]);

     const getStates = async(country_code)=>{
      var d = await service.getRequest('getStatesbyCountryCode/'+country_code)       
      if(d.status===200){           
        setStates(d.data.data)
        if(d.data.country){
          setCountyCode(d.data.country.sortname)
        }
      }
    }

    const sendOTP = (e) =>{

        console.log('phone_number',phone_number)
        let payload ={ 
            phone_number:phone_number 
        
        }
        otpAction(payload).then(async (res)=>{   

            console.log(res)
             if(res ===true){     
              
               
            }  
        });
    }

    const getBase64 = file => {
      return new Promise(resolve => {
        let fileInfo;
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();
        // Convert the file to base64 text
        reader.readAsDataURL(file);
        // on reader load somthing...
        reader.onload = () => {
          // Make a fileInfo Object
          console.log("Called", reader);
          baseURL = reader.result;
          console.log(baseURL);
          resolve(baseURL);
        };
       // console.log(fileInfo);
      });
    };
    const handleFileInputChange = e => {
      console.log(e.target.name);
      
      var file = e.target.files[0];
      getBase64(file)
        .then(result => {
          file["base64"] = result;
          console.log("File Is", file.base64);
          if(e.target.name=='front_id'){
            setFrontId( file.base64)
            setFrontIdErr(false)
          }
          if(e.target.name=='back_id'){
            setBackId( file.base64)
            setBackIdErr(false)
          }
          if(e.target.name=='second_front_id'){
            setSecondFrontId( file.base64)
            setSecondFrontIdErr(false)
          }
          if(e.target.name=='second_back_id'){
            setSecondBackId( file.base64)
            setSecondBackIdErr(false)
          }
         
        })
        .catch(err => {
          console.log(err);
        });
  
     
      }

  
      

      // const onChange = (imageList, addUpdateIndex,errors) => {
      //   setImages(imageList);
      // };

      // const imgErr = (err) =>{
      //   console.log('imgErr',err)
      // }

      return (
  
       <>
        <section className="section-b-space animated-section">
            <div className="container">
                <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className='col-md-12 col-sm-12 col-xs-12'>
                      <h1 className='heding-span'>Welcome to Idowaz</h1>
                      <h5 className='m-b-30'> Sign up for lightning fast delivery to your customers </h5>
                    </div>
                    <form className='manformcls manformcls2new' onSubmit={handleSubmit}>

                      
                        <div className="row">
                            <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                                <label for="" class="form-label">First Name <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" placeholder="First Name" value={first_name} onChange={(e) => {setFirstName(e.target.value)
                                   setFirstNameErr(false)}} />
                                {first_nameErr && <p className="field-err">Please enter First Name </p>}
                            </div>

                            <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                                <label for="" class="form-label">Last Name <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" placeholder="Last Name" value={last_name} onChange={(e) =>{ setLastName(e.target.value)
                                setLastNameErr(false)
                                } } />
                                {last_nameErr && <p className="field-err">Please enter Last Name </p>}
                            </div>
                        </div>
                        <div className="row">
                          <div class="col-md-12 col-sm-12 col-xs-12 form-group">
                              <label for="exampleInputEmail1" class="form-label">Email Address <span class="text-danger">*</span></label>
                              <input type="email" class="form-control" placeholder="Email Address" value={email} onChange={(e) => {setEmail(e.target.value)
                            setEmailErr(false) } }/>
                              {emailErr && <p className="field-err">Please enter Email </p>}
                          </div>
                        </div>
                        <div className="row">
                            <div class="col-md-6 col-sm-12 col-xs-12 form-group">
                                <label for="phone" class="form-label">Phone <span class="text-danger">*</span></label>
                                <PhoneInput
                                country={'in'}
                                placeholder="Enter phone number"
                                value={phone_number}
                                onChange={(value,data) => {
                                  console.log(data)
                                  getStates(data.dialCode);
                                  setPhoneNumber(value)
                                  setPhoneCode(data.dialCode)
                                  setPhoneNumberErr(false)
                                }}  
                               />
                                <span onClick={(e)=>sendOTP(e)} className="makeAnchor">Send OTP</span>
                                {phone_numberErr && <p className="field-err">Please enter Phone Number </p>}
                            </div>
                        

                          <div class="col-md-6 col-sm-12 col-xs-12 form-group">
                              <label for="otp" class="form-label">OTP <span class="text-danger">*</span></label>
                              <input type="text" class="form-control" placeholder="OTP" value={otp} onChange={(e) => {setOtp(e.target.value)
                              
                              setOtpErr(false)
                              } }/>
                              {otpErr && <p className="field-err">Please enter OTP </p>}
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                              <label for="exampleInputPassword1" class="form-label">Password <span class="text-danger">*</span></label>
                              <input type="password" class="form-control" placeholder="Password" value={password} onChange={(e) => {setPassword(e.target.value)
                              setPasswordErr(false)}} />
                              {passwordErr && <p className="field-err">Please enter Password </p>}
                          </div>
                          
                          <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                              <label for="exampleInputPassword1" class="form-label">Confirm Password <span class="text-danger">*</span></label>
                              <input type="password" class="form-control" placeholder="Confirm Password" value={confirm_password} onChange={(e) => {setConfirmPassword(e.target.value)
                              
                              setConfirmPasswordErr(false)}} />
                              {confirm_passwordErr && <p className="field-err">Please enter correct confirm password </p>}
                           </div>
                        </div>
                        <div className="row">
                          <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                            <label for="inputState">State</label>
                                <select class="form-control" name="state" onChange={(e)=>{setState(e.target.value)
                                  setStateErr(false)
                                }} value={state} >
                                <option value="">Select State</option>
                                {states.map(function(object, i){
                                return (<option value={object.id} key={i}>{object.name}</option>)
                                })}
                            </select>
                            {state_err && <p className="field-err">Please select state </p>}

                          </div>

                          <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                              <label for="inputCity">City</label>
                                  <select class="form-control" name="city" onChange={(e)=>{setCity(e.target.value)
                                    setCityErr(false)
                                  } } value={city}>
                                  <option value="">Select City</option>
                                  {cities && cities.map(function(object, i){
                                  return (<option value={object.id} key={i}>{object.name}</option>)
                                  })}
                              </select>
                              {city_err && <p className="field-err">Please select city </p>}
                          </div>

                        </div>

                        <div class="row">
                          <div class="col-md-12 col-sm-12 col-xs-12 form-group">
                                  <label for="" class="form-label">Zipcode <span class="text-danger">*</span></label>
                                  <input type="text" class="form-control" placeholder="Zipcode" value={zip} onChange={(e) =>{ setZip(e.target.value)
                                  setZipErr(false)
                                } } />
                                {zip_err && <p className="field-err">Please enterZip code </p>}
                            </div>
                        </div>

                       <div className="row">
                          <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                              <label for="" class="form-label">Frond ID <span class="text-danger">*</span></label>
                              <input type="file" class="form-control"  name="front_id" onChange={handleFileInputChange}/>
                              {front_id_err && <p className="field-err">Please select Front Id</p>}
                          </div>
                          <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                              <label for="" class="form-label">Back ID <span class="text-danger">*</span></label>
                              <input type="file" class="form-control"  name="back_id" onChange={handleFileInputChange}/>
                              {back_id_err && <p className="field-err">Please select Back Id</p>}
                          </div>
                        </div>
                        <div className="row">
                          <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                              <label for="" class="form-label">Frond ID <span class="text-danger">*</span></label>
                              <input type="file" class="form-control" name="second_front_id" onChange={handleFileInputChange} />
                              {second_front_id_err && <p className="field-err">Please select Second Front Id</p>}
                          </div>
                          <div class="col-md-6 col-sm-6 col-xs-12 form-group">
                              <label for="" class="form-label">Back ID <span class="text-danger">*</span></label>
                              <input type="file" class="form-control"  name="second_back_id" onChange={handleFileInputChange} />
                              {second_back_id_err && <p className="field-err">Please select second Back Id</p>}
                          </div>
                        </div>

                         
                        <div className='col-md-12 col-sm-12 col-xs-12 form-group'>
                          <label className='cont-radio'>
                            <input type="checkbox"  checked={terms}
                                onChange={(e) =>{ setTerms(e.target.checked)
                                    if(e.target.checked){
                                    setTermsErr(false)
                                    }else{
                                    setTermsErr(true)
                                    }
                                }}  />
                            <span className="checkmark"></span>
                            &nbsp;  I agree to <Link to="/terms-conditions"> 
                            <span className="term-cond"> Terms &amp; Conditions </span></Link>
                          </label>
                          {termsErr && <p className="field-err">Please checked terms </p>}
                        </div>
                      

                    <GoogleReCaptchaProvider reCaptchaKey="6Ld-ZA0bAAAAAIW-_0mdnJ08FgDiiVCzne2fGtGt">
                      <GoogleReCaptcha  onVerify={token => {
                            setToken(token);
                            setTokenErr(false)
                          }} />
                    </GoogleReCaptchaProvider>
                    {tokenErr && <p className="field-err">Please verify captcha  </p>}
                        <button type="submit" class="btn btn-solid color1" onClick={(e)=>handleSubmitForm(e)}>Register</button>
                    </form>
                 </div>
                 <div className='col-md-6 col-sm-6 col-xs-12'>
                        <div className='user-sec-img'>
                              <img className='img-responsive' src='https://idowaz.com/public/image/ezgif.png' alt='idowaz' />
                        </div>
                 </div>
                </div>
            </div>
        </section>
        <section className='why-sec'>
                <div className='container'>
                  <div className='why-panel'>
                        <div className='row'>
                          <h1 className='heding-span'> Get listed on UK's leading online Hotel Booking marketplace today </h1>
                          <div className='col-md-3 col-sm-3 col-xs-12'>
                            <div className='why-box'>
                              <div className='why-head first'>
                                <div className='why-no'>
                                  I
                                </div>
                                <div className='why-heading'>
                                  Submit your details 
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-3 col-sm-3 col-xs-12'>
                            <div className='why-box'>
                              <div className='why-head second'>
                                <div className='why-no'>
                                  II
                                </div>
                                <div className='why-heading'>
                                Sign service agreement 
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-3 col-sm-3 col-xs-12'>
                            <div className='why-box'>
                              <div className='why-head third'>
                                <div className='why-no'>
                                  III
                                </div>
                                <div className='why-heading'>
                                Product training & photoshoot 
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-3 col-sm-3 col-xs-12'>
                            <div className='why-box'>
                              <div className='why-head fourth'>
                                <div className='why-no'>
                                  IV
                                </div>
                                <div className='why-heading'>
                                Go live & start getting orders  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                  </div>
                </div>
        </section>
    </>
  );

}

export default Signup;
