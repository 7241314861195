import React ,{useState,useEffect} from 'react'
import { Image } from 'react-bootstrap';
import { Link , useParams} from 'react-router-dom'
//import {service} from '../services.js'
import {  useSelector  } from "react-redux";
import * as authActions from "../../redux/actions/auth";
import {useActions} from "../../redux/actions";

import moment from 'moment';
import {service} from '../../services.js'
const NoOFdays = ({checkIn,checkOut})=>{
     const [days,setDays] = useState(1);

      useEffect(() => {   
            var start = moment(checkIn, "YYYY-MM-DD");
            var end = moment(checkOut, "YYYY-MM-DD");
            var days = moment.duration(end.diff(start)).asDays();
            setDays(days)

        },[]);
        return (
            <>{days}</>
            )
    }
    
export default function BookingSummary(props) {

    
    const [property,setProperty] = useState();
    const [base_price,setBasePrice] = useState(0)
    const [disocunt_price,setDiscountPrice] = useState(0)
    const [coupon_discount,setCouponDiscount] = useState(0)
    const [total_amount,setTotalAmount] = useState(0)

    const [tax,setTax] = useState(0)

    const {removeFromCart} = useActions(authActions)

    const cart = useSelector((state)=> state.cart)
    const coupon = useSelector((state)=> state.coupon)

    
    useEffect(async () => {   
        console.log(cart,'cccccccc')
        if(cart.length>0){
            getProperty(cart[0].detail.property_id)    
        }
    },[]);

    const removeCoupon = () => {
          localStorage.removeItem('coupon');
          window.location.reload(false);
    }
   
   
     useEffect(async () => {   

        
        var cDisocunt=0;
        
        var b_price = 0;
        var d_price = 0;
        cart.map((t)=>{

            var start = moment(t.checkIn, "YYYY-MM-DD");
            var end = moment(t.checkOut, "YYYY-MM-DD");
            var days = moment.duration(end.diff(start)).asDays();
           

            var b_prc = t.rooms * t.detail.original_price;
            var d_prc = t.rooms * t.detail.discount_price;

            if(days >0){
                b_price = b_price + b_prc * days
                d_price = d_price + d_prc * days
            }

        })

        setBasePrice(b_price)
        setDiscountPrice(d_price)

       
        if(coupon && coupon.type){
            if(coupon.type=='percent'){
                cDisocunt = b_price * coupon.amount/100
            }else{
                cDisocunt = coupon.amount
            }
        }

        setCouponDiscount(cDisocunt)
        var total = disocunt_price-coupon_discount

        console.log(property,'property')
        if(property && property.tax_detail && property.tax_detail.tax){

            if(property.tax_detail.type=='value'){
                var val= property.tax_detail.tax
              total =  total + val
               setTax(val)
                console.log('hreee',val,tax)
            }else{
                var val = total * property.tax_detail.tax/100
                total =  total +  val
                setTax(val)
            }

        }

        console.log(total,'totaltotal',tax)
        setTotalAmount(total)


    },[cart,property,coupon]);

    
    const getProperty = async (id) => {
        var d = await service.getRequest('properties/'+id)       
        if(d.status===200){
            setProperty(d.data.data)                 
        }   
    }


    const removeCart = (item) => {
        console.log('item','rrrritem')
        removeFromCart(item)
    }
    

    return (
       <>
        <div class="col-lg-5 booking-order">
                            <div class="summery-box">
                            
                                <h2>booking summary </h2>
                                {property ?
                                (<div class="hotel-section">
                                    <div class="hotel-img">
                                        <img src={property.featured_image?.images} class="img-fluid blur-up lazyload" alt="" width="400" />
                                    </div>
                                    <div class="hotel-detail">
                                        <h6>{property.property_name}</h6>
                                        <p>{property.address}</p>
                                    </div>
                                </div>)
                                :''}
                            
                                <div class="summery-section row">   
                               
                               {cart.length>0 ? cart.map(function(item, i){    

                               return (              
                                    <>
                                    <div class="box col-sm-12" style={{"paddingBottom":"5"}} >
                                    <button type="button" class="btn-close" aria-label="Close" onClick={(e)=>removeCart(item)}></button>
                                        <div>
                                            <h5><b>Room: {item.detail.title}</b>({item.rooms} - Rooms)</h5>
                                            <h5><b>Nights: <NoOFdays checkIn={item.checkIn} checkOut={item.checkOut} /></b></h5>

                                        </div>
                                        <div class="left">
                                            <div class="up">
                                                <h6>check in</h6>
                                                <h5>{ moment(item.checkIn).format("MM/DD/Y")}</h5>
                                            </div>
                                            <div class="down">
                                                <h6>check in time</h6>
                                                <h5>{ moment(item.checkIn).format("h:mm A")}</h5>
                                            </div>
                                             <div class="down">
                                                <h6>Adults </h6>
                                                <h5>{item.adults}</h5>
                                            </div>
                                        </div>
                                        <div class="right">
                                            <div class="up">
                                                <h6>check out</h6>
                                                <h5>{ moment(item.checkOut).format("MM/DD/Y")}</h5>
                                            </div>
                                            <div class="down">
                                                <h6>check out time</h6>
                                                <h5>{ moment(item.checkOut).format("h:mm A")}</h5>
                                                
                                            </div>
                                            <div class="down">
                                                <h6>Children</h6>
                                                <h5>{item.childrens}</h5>
                                                
                                            </div>
                                        </div>

                                        

                                        <hr/>
                                    </div>
                                    <div class="summery-section">
                                   
                                </div>
                                
                                </>
                                  
                                    )
                                }): (
                                <h5><center>Empty Cart</center></h5>
                                )}

                                {cart.length>0 ? 
                                (
                                    <div class="payment-details">
                                        <h5>payment details</h5>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>base price</td>
                                                    <td>₦{base_price.toLocaleString('hi-IN')}</td>
                                                </tr>
                                                <tr>
                                                    <td>discount</td>
                                                    <td>- ₦{base_price-disocunt_price}</td>
                                                </tr>
                                                <tr>
                                                    <td>Coupon Applied - <b>{coupon?coupon.coupon_code :''}</b>
                                                    {coupon && coupon.coupon_code && (
                                                        <>
                                                    <span onClick={removeCoupon} style={{fontSize:"12px",color:'red','cursor':'pointer'}}> Remove</span>
                                                    <br></br>
                                                    <p style={{fontSize:"10px",lineHeight:"1.3em"}}>**{coupon.description}**</p>
                                                    </>
                                                    )}
                                                    </td>
                                                    <td>- ₦{coupon_discount.toLocaleString('hi-IN')}</td>
                                                </tr>

                                                <tr>
                                                    <td>tax & service fees</td>
                                                    <td>+ ₦{tax.toLocaleString('hi-IN')}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="summery-section">
                                            <div class="payment-details">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>payable amount</td>
                                                            <td class="amount">₦{total_amount.toLocaleString('hi-IN')}</td>
                                                        
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    
                                )
                                :(<p></p>)
                                }
                                </div>
                             
                            </div>
                        </div>
       </>
    )
}
