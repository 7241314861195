import React,{useEffect,useState} from 'react'
import {Image } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import {service} from '../../services.js'
import Slider from "react-slick"; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-datetime/css/react-datetime.css';
import Hotel from './Hotel'

export default function RelatedProperties(props) {

    const [properties, setProperties] = useState([]);

    const getProperties = async (url) => {

       
        var d = await service.getRequest(url)  
       console.log(d)
        if(d.status===200){
            setProperties(d.data.data)           
        }
        
    }

    useEffect(() => {   
        
            getProperties('related_properties/2')
    },[]);


    console.log('propertiespropertiesproperties',properties,properties.length)

  var relatedSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: properties.length>1?2:1,
    slidesToScroll: 2,
    slickGoTo:1
  };
  console.log(relatedSettings)
    return (
       <Slider {...relatedSettings} className="">
        {properties.length>0 && properties.map(function(property, i){
           return ( <div>
             <Hotel data={property} key={i} type="related" />

             
            </div> )
        })}
           
        </Slider>
    )
}

