import axios from "axios";
import config from './config.json'


var API_URL = config.API_URL


export const service = {
    getRequest,
    postAuthRequest,
    getExternalRequest
};


function getRequest(url,payload={}) {
     const config = {
                headers: { Authorization: "jwt "+payload?.token,
                'Access-Control-Allow-Origin':'*'
                 }
            };

            console.log('config',config)

          
    return axios.get(API_URL+`/`+url,config).then( (res) =>{

        return res
    }).catch( (err)=>{

        return err
    })
}


function postAuthRequest(url,payload) {
    const config = {
                headers: { Authorization: "jwt "+payload.token },
                  'Access-Control-Allow-Origin':'*'
            };

    return axios.post(API_URL+`/`+url, payload, config).then( (res) =>{

        return res
    }).catch( (err)=>{

        return err
    })
}


function getExternalRequest(url) {
    
          
    return axios.get(url,{}).then( (res) =>{

        return res
    }).catch( (err)=>{

        return err
    })
}
